import { Col, Row, Card, Space, Button, List } from "antd";
import React, { Component } from "react";
import axios from "axios";
import "../css/base.css";
import { styled } from "@mui/material/styles";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
} from "react-vis";
import moment from "moment";
import { PlusCircleFilled, ReloadOutlined } from "@ant-design/icons";
import NewDataModal from "./NewDataModal";
import Proposta from "./newProposta";
import {
  API_URL_CLIENTES,
  API_URL_PROPOSTAS,
  API_URL_TARIFARIO,
} from "../constants";
import PersistedStore from "../store";
import LeaveModal from "./LeaveModal";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "moment/locale/pt";

/**
 * Dashboard for diferent logins
 */
class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propostas: [],
      tarifarios: [],
      clientes: [],
      sadmin: [],
      admin: [],
    };

    this.menu = this.menu.bind(this);
  }

  componentDidMount() {
    this.getRedux();
    this.getData();
  }
  /**
   * Get data from API
   */
  getData = (e) => {
    axios.get(API_URL_TARIFARIO).then((res) => {
      var data = res.data;
      this.setState({ tarifarios: data });
    });
    axios.get(API_URL_PROPOSTAS).then((res) => {
      var local = res.data;
      this.setState({ propostas: local });
    });

    axios.get(API_URL_CLIENTES).then((res) => {
      var data = res.data;
      this.setState({ clientes: data });
    });
  };
  /**
   * Get data from redux store
   */
  getRedux() {
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().userState;
    const login = store.getState().loginState;
    this.setState({ login: login.name, id: val.id });
  }

  /**
   * Reset state
   * Get new and updated data from the API
   */
  resetState = (e) => {
    this.getData();
  };

  /**
   * Renders Proposta state to a Badge
   */
  badge() {
    const Div = styled("div")(({ theme }) => ({
      backgroundColor: "#F5D994",
      fontWeight: "bold",
      color: "#646464",
      alignContent: "center",
    }));

    return <Div>EM APROVAÇÃO</Div>;
  }

  /**
   * List of Propostas using Cards
   */
  lista() {
    let d = this.state.propostas.filter((p) => p.status === "EM APROVACAO");
    const s = 5;
    if (!d || d.length === 0) return "Não existem dados!";
    else
      return (
        <>
          <div style={{ paddingTop: 50 }}>
            <h5>Propostas em Aprovação</h5>
            <div style={{ textAlign: "end" }}>
              <ReloadOutlined
                onClick={this.resetState}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          <Card
            className="list_menu justify-content-center"
            style={{ textAlign: "center", alignItems: "center" }}
          >
            <Row>
              <Col span={4} className="list_nome">
                CLIENTE
              </Col>
              <Col span={4}>CRIADO </Col>
              <Col span={s}>RESPONSAVEL</Col>
              {/* <Col span={5}>TARIFÁRIO</Col> */}
              <Col span={5} className="list_badge justify-content-right">
                STATUS
              </Col>
              <Col span={5}>AÇÕES</Col>
            </Row>
          </Card>

          <List
            pagination={{ pageSize: 5 }}
            grid={{ column: 1 }}
            dataSource={d}
            renderItem={(p) => (
              <List.Item className="list_item justify-content-center">
                <Card className="list_card ">
                  <Row>
                    <Col span={4} className="list_nome">
                      {this.onNomeCliente(p.client_id)}
                    </Col>
                    <Col span={4}>{p.criado} </Col>
                    <Col span={s}>{p.responsavel}</Col>
                    {/* <Col span={5}>{"Tarifário " + this.tari(p.tarifario)}</Col> */}
                    <Col span={5} className="list_badge">
                      {this.badge(p.status)}
                    </Col>
                    <Col span={3} className="list_acao">
                      <NewDataModal
                        info="propostas"
                        data={p}
                        resetState={this.resetState}
                      />
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )}
          />
        </>
      );
  }

  /**
   * Updates state menu variable
   * @param  {string} m
   */
  menu = (m) => {
    this.setState({ menu: m });
  };

  /**
   * Get id of list of tarifarios
   * @param {id} e
   * @returns id
   */
  tari = (e) => {
    // if (this.state.tarifarios.length !== 0 && e.length !== 0) {
    //   let i = 0
    //   while (i < e.length) {
    //     let obj = this.state.tarifarios.filter(p => p.id === parseInt(e[i]))
    //     if (obj.length !== 0) {
    //       let o = obj.pop()
    //       if (o !== undefined) {
    //         if (o.name !== undefined) {
    //           return o.id.toString()
    //         } return ""
    //       } return ""
    //     }
    //     i++
    //   }
    //   return ""
    // }
  };

  /**
   * In case it wants to create a new Proposta it switchs components
   */
  dashboard() {
    let dash = this.state.menu;
    switch (dash) {
      case "proposta":
        return (
          <>
            <Row style={{ paddingTop: 25 }}>
              <Col span={4}></Col>
              <Col span={15} style={{ textAlign: "right" }}>
                <LeaveModal menu={this.menu} />
              </Col>
              <Col span={4}></Col>
            </Row>

            <Proposta menu={this.menu} />
          </>
        );
      default:
        return this.inicio();
    }
  }

  /**
   * Get name of the client using its ID number
   * @param {id} e
   * @returns name
   */
  onNomeCliente = (e) => {
    let cliente = this.state.clientes;
    if (!cliente || cliente.length === 0) {
      return "";
    } else {
      let user = cliente.filter((p) => p.id === e);

      let u = user.pop();
      return u.name;
    }
  };
  /**
   * Map data along the months
   * data - Propostas enviadas
   */
  dataPropostasEnviadas() {
    const currentYear = moment().year();
    const mesAno = Array.from({ length: 12 }, (_, index) => ({
      mes: index + 1,
      ano: currentYear,
    }));

    return mesAno.map((item) => this.filtroMesEnviadas(item.mes));
  }
  /**
   * Turn data into objects
   * @param {list} m Months
   * @returns obj
   */

  filtroMesEnviadas = (m) => {
    const propostas = this.state.propostas;

    const obj = {
      x: moment()
        .set("month", m - 1)
        .locale("pt")
        .format("MMMM"),
      y: 0,
    };

    const s = propostas.filter(
      (r) => moment(r.criado, "YYYY-MM-DD").month() === m - 1
    );

    obj.y = s.length;

    return obj;
  };

  /**
   * Switch components depending on which type of user is logged in
   */
  inicio() {
    const tipo = this.props.tipo;
    let penv = this.state.propostas.filter((pr) => pr.status != "");

    const dataPropostas = this.dataPropostasEnviadas();

    const chartData = {
      labels: dataPropostas.map((obj) => obj.x),
      datasets: [
        {
          label: "Propostas Enviadas",
          data: dataPropostas.map((obj) => obj.y),
          backgroundColor: "rgba(249, 77, 90, 0.6)",
          borderColor: "rgba(249, 77, 90, 1)",
          borderWidth: 1,
        },
      ],
    };

    // eslint-disable-next-line default-case
    switch (tipo) {
      case "sadmin": {
        return (
          <div>
            <Row
              style={{ paddingTop: 50 }}
              className="row justify-content-center"
            >
              <Col> </Col>
              <Col span={15}>
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <div className="inicio">
                      <h5>Nova Proposta</h5>
                      Crie uma nova proposta para um cliente
                      <p></p>
                      <div
                        onClick={() => {
                          this.menu("proposta");
                        }}
                      >
                        <Space>
                          <PlusCircleFilled
                            style={{
                              fontSize: "30px",
                              color: "#F94D5A",
                              cursor: "pointer",
                            }}
                          />
                          <h6 style={{ paddingTop: 7, cursor: "pointer" }}>
                            Criar Proposta{" "}
                          </h6>
                        </Space>
                      </div>
                    </div>
                  </Col>

                  <Col span={2} />

                  <Col span={8}>
                    <Card
                      className="list_card"
                      style={{ width: 500, height: 300 }}
                    >
                      <h7>Propostas Enviadas</h7>
                      <p>
                        <h8>{penv.length}</h8>
                      </p>
                      <p>
                        <Bar
                          data={chartData}
                          options={{
                            scales: {
                              x: {
                                type: "category", // Usar escala de categoria para o eixo x
                                position: "bottom",
                                title: {
                                  display: true,
                                  text: "Meses",
                                },
                              },
                              y: {
                                beginAtZero: true,
                                title: {
                                  display: true,
                                  text: "Número de Propostas",
                                },
                              },
                            },
                          }}
                        />
                        {/* <XYPlot
                          xType="ordinal"
                          yType="ordinal"
                          width={400}
                          height={150}
                        >
                          <VerticalGridLines />
                          <HorizontalGridLines />
                          <XAxis />
                          <YAxis />
                          <VerticalBarSeries
                            data={this.dataPropostasEnviadas()}
                          />
                        </XYPlot> */}
                      </p>
                    </Card>
                  </Col>
                </Row>
                <div>{this.lista()}</div>
              </Col>
              <Col></Col>
            </Row>
          </div>
        );
      }
      case "admin": {
        return (
          <div>
            <Row
              style={{ paddingTop: 50 }}
              className="row justify-content-center"
            >
              <Col span={4} />
              <Col span={16}>
                <div className="inicio_u">
                  <h4>Nova Proposta</h4>
                  <h7>Crie uma nova proposta para um cliente</h7>
                  <p></p>
                  <PlusCircleFilled
                    style={{ fontSize: "30px", color: "#F94D5A" }}
                    onClick={() => {
                      this.menu("proposta");
                    }}
                  />{" "}
                  Criar Proposta
                </div>
                <div style={{ paddingTop: 50 }}>{this.lista()}</div>
              </Col>
              <Col span={4} />
            </Row>
          </div>
        );
      }
      case "user": {
        return (
          <div>
            <Row
              className="row justify-content-center"
              style={{ paddingTop: 20 }}
            >
              <Col></Col>
              <Col span={18}>
                <div className="inicio_proposta">
                  <h4>Nova Proposta</h4>
                  <h7>Crie uma nova proposta para um cliente</h7>
                  <p></p>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.menu("proposta");
                    }}
                  >
                    <Space>
                      <PlusCircleFilled
                        style={{ fontSize: "30px", color: "#F94D5A" }}
                      />
                      <h5>Criar Proposta</h5>
                    </Space>
                  </div>
                </div>
                <div>{this.lista()}</div>
              </Col>
              <Col></Col>
            </Row>
          </div>
        );
      }
    }
  }

  render() {
    return <div>{this.dashboard()}</div>;
  }
}

export default Inicio;
