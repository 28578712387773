import React, { Component } from 'react';
import '../css/base.css';
import { Divider, Input, Layout, Menu, Select, Space, Affix, Button,Segmented, Row, Col, Form } from 'antd';
import { PlusCircleFilled, FilterFilled, ReloadOutlined, SettingFilled, EyeFilled, UserOutlined,LogoutOutlined, CloseOutlined } from '@ant-design/icons';
import PersistedStore from '../store';
import { Option } from 'antd/lib/mentions';

const { Content, Sider } = Layout;

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dash: 0,
            login:'',
        }
    }



  componentDidMount() {
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().loginState;

    this.setState({ login: val.name });
   
  }


  
    /**
     * Updates dash variable in state
     * @param int dash Dashboar variable
     * */
    alt = (dash) => {
        this.setState({ dash: dash });
    }

    /**
      * Alternate between different components in dashboard
      */
    dashboard() {

        let dash = this.state.dash;

        switch (dash) {


            case 0: return ("Selecione uma opção.")
            case 1: return (<>
                <h5 id="#anchor-1">Criar Proposta</h5>
                < div>
                    <p />
                    <Space size={20}><div style={{ paddingTop: 3 }}>Dashboard {'>'}</div><PlusCircleFilled style={{ fontSize: '20px', color: "#F94D5A", paddingTop: 4 }} /><div style={{ paddingTop: 3 }}>Criar Proposta</div> </Space>
                    <p />
                    ou
                    <p />
                    <Space size={20}><div style={{ paddingTop: 3 }}>Propostas {'>'}</div>< PlusCircleFilled style={{ fontSize: '20px', color: "#F94D5A" }} /><div style={{ paddingTop: 3 }}>Criar Proposta</div> </Space>

                </div>

                <Divider />

                <h5 id="#anchor-1">Criar/Editar Planos de Proposta</h5>
                < div>
                    <p>
                        <Space size={20} style={{paddingTop:3}}><div>Adicionar nova linha de tarifário {'>'}</div>
                            <PlusCircleFilled style={{fontSize: '20px', color: "#F94D5A", paddingTop: 4}}/>
                            <div style={{ paddingTop: 3 }}>Adicionar  tarifário</div>
                        </Space>
                    </p>
                </div>
                < div>
                    <p>
                        <Space size={20} style={{paddingTop:3}}><div>Remover linha de tarifário {'>'}</div>
                            <CloseOutlined style={{fontSize: '20px', color: "#F94D5A", paddingTop: 4}}/>
                        </Space>
                    </p>
                </div>

                <Divider />
                
                <h5 id="#anchor-2">Editar Status da Proposta</h5>
                <div>
                    <p />
                    No separador Propostas:
                    <p />
                    Selecione a proposta que pretende atualizar o status clicando em:
                    <p /><Space> Exemplo:(<div style={{ backgroundColor: '#F5D994', cursor: "pointer", alignContent: 'center',width:120,
          fontWeight: "bold", color: '#646464' }}>EM APROVAÇÃO</div>) </Space>
                    
                    <p />
                    Aparecerá um Popup com todas as opções disponíveis.
                    <p />
                    <Segmented options={['APROVADO', 'REPROVADO', 'ARQUIVADO', 'EM APROVAÇÃO']} />
                    <p />
                    Selecione o status pretendido e será guardado automáticamente.
                  


                </div>
                <Divider />

                <h5 id="#anchor-2">Editar Proposta</h5>
                <div>
                    <p />
                    No separador Propostas:
                    <p />
                    Selecione a proposta que pretende editar clicando em <EyeFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <p />
                    Aparecerá um Popup com todos os campos preenchidos dessa mesma proposta.
                    <p />
                    Altere os dados pretendidos e clique em  <Button danger>Guardar</Button>
                    <p />
                    O Popup irá fechar e aparecerá um notificação a confirmar que as alterações foram guardadas.


                </div>
                <Divider />

                <h5 id="#anchor-2">Filtrar Proposta</h5>
                <div>


                    <p />
                    Pesquisar por cliente ou pelo nif:
                    <p />
                    <Input style={{ width: 300 }} disabled /> <FilterFilled style={{ fontSize: '25px', color: "#F94D5A" }} />
                    <p />Insira o nome do cliente ou o nif na barra de pesquisa carregue no ícone de filtro.
                    <p />
                    Pesquisar por estado da proposta:
                    <p />
                    <Select style={{ width: 200 }} disabled />
                    <p />
                    Selecione no menu central.
                </div>


            </>)
            case 9: return (<>
                <h5 id="#anchor-1">Criar um Cliente</h5>
                < div>
                    <p />
                    <Space size={20}><div style={{ paddingTop: 3 }}>Dashboard {'>'}</div><PlusCircleFilled style={{ fontSize: '20px', color: "#F94D5A", paddingTop: 4 }} /><div style={{ paddingTop: 3 }}>Criar Proposta</div> </Space>
                    <p />
                    ou
                    <p />
                    <Space size={20}><div style={{ paddingTop: 3 }}>Propostas {'>'}</div>< PlusCircleFilled style={{ fontSize: '20px', color: "#F94D5A" }} /><div style={{ paddingTop: 3 }}>Criar Proposta</div> </Space>


                </div>
                <Divider />
                <h5 id="#anchor-2">Editar Cliente</h5>
                <div>
                    <p />
                    No separador Clientes:
                    <p />
                    Selecione o cliente que pretende editar clicando em <EyeFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <p />
                    Aparecerá um Popup com todos os campos preenchidos dessa mesmo cliente.
                    <p />
                    Altere os dados pretendidos e clique em  <Button danger>Guardar</Button>
                    <p />
                    O Popup irá fechar e aparecerá um notificação a confirmar que as alterações foram guardadas.


                </div>
                <Divider />

                <h5 id="#anchor-2">Filtrar Cliente</h5>
                <div>
                    <p />
                    Pesquisar por cliente ou pelo nif:
                    <p />
                    <Input style={{ width: 300 }} disabled /> <FilterFilled style={{ fontSize: '25px', color: "#F94D5A" }} />
                    <p />Insira o nome do cliente ou o nif na barra de pesquisa carregue no ícone de filtro.
                    <p />

                </div>
            </>)

            case 3: return (<>
                <h5 id="#anchor-3">Obter os dados mais recentes</h5>
                < div>
                    <p />
                    <Space size={7}><div style={{ paddingTop: 3 }}>Carregue ícone de atualizar {'>'}</div> <ReloadOutlined style={{ fontSize: '20px', color: "#F94D5A" }} /></Space>

                </div>
            </>)
            case 4: return (<>
                <h5 id="#anchor-4">Criar Tarifário</h5>
                <p />
                <Space >
                    <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <div style={{ paddingTop: 3 }}>Definições {'>'} Tarifários {'>'} Criar</div>
                </Space>


                <Divider />

                <h5 id="#anchor-2">Editar Tarifário</h5>
                <div>
                    <p />
                    <Space >
                        <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                        <div style={{ paddingTop: 3 }}>Definições {'>'} Tarifários {'>'} Consultar</div>
                    </Space>

                    <p />
                    Selecione o tarifário que pretende editar clicando em <EyeFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <p />
                    Aparecerá um Popup com todos os campos preenchidos dessa mesmo tarifário.
                    <p />
                    Altere os dados pretendidos e clique em  <Button danger>Guardar</Button>
                    <p />
                    O Popup irá fechar e aparecerá um notificação a confirmar que as alterações foram guardadas.


                </div>
                <Divider />

                <h5 id="#anchor-2">Filtrar Tarifário</h5>
                <div>
                    <p />
                    <Space >
                        <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                        <div style={{ paddingTop: 3 }}>Definições {'>'} Tarifários {'>'} Consultar</div>
                    </Space>

                    <p />
                    Pesquisar por tarifário:
                    <p />
                    <Input style={{ width: 300 }} disabled /> <FilterFilled style={{ fontSize: '25px', color: "#F94D5A" }} />
                    <p />Insira o nome do tarifário na barra de pesquisa carregue no ícone de filtro.

                </div>

            </>)
            case 5: return (<>
                <h5 >Alterar os seus dados</h5>
                <p />
                O seu nome {'>'} <UserOutlined style={{  color: "#F94D5A"}} /> Perfil

            </>)
            case 6: return (<>  <h5>Criar Utilizador</h5>
                <p />
                <Space >
                    <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <div style={{ paddingTop: 3 }}>Definições {'>'} Utilizadores {'>'} Criar</div>
                </Space>


                <Divider />

                <h5 id="#anchor-2">Editar Utilizador</h5>
                <div>
                    <p />
                    <Space >
                        <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                        <div style={{ paddingTop: 3 }}>Definições {'>'} Utilizadores {'>'} Consultar</div>
                    </Space>

                    <p />
                    Selecione o Utilizador que pretende editar clicando em <EyeFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <p />
                    Aparecerá um Popup com todos os campos preenchidos dessa mesmo Utilizador.
                    <p />
                    Altere os dados pretendidos e clique em  <Button danger>Guardar</Button>
                    <p />
                    O Popup irá fechar e aparecerá um notificação a confirmar que as alterações foram guardadas.


                </div>
                <Divider />

                <h5 id="#anchor-2">Filtrar Utilizador</h5>
                <div>
                    <p />
                    <Space >
                        <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                        <div style={{ paddingTop: 3 }}>Definições {'>'} Utilizadores {'>'} Consultar</div>
                    </Space>

                    <p />
                    Pesquisar por Utilizador:
                    <p />
                    <Input style={{ width: 300 }} disabled /> <FilterFilled style={{ fontSize: '25px', color: "#F94D5A" }} />
                    <p />Insira o nome do Utilizador na barra de pesquisa carregue no ícone de filtro.

                </div>

            </>)
            case 7: return (<> 
            <h5>Criar Administrador</h5>
                <p />
                <Space >
                    <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <div style={{ paddingTop: 3 }}>Definições {'>'} Administradores {'>'} Criar</div>
                </Space>

                <Divider />

                <h5 id="#anchor-2">Editar Administrador</h5>
                <div>
                    <p />
                    <Space >
                        <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                        <div style={{ paddingTop: 3 }}>Definições {'>'} Administradores {'>'} Consultar</div>
                    </Space>

                    <p />
                    Selecione o Administrador que pretende editar clicando em <EyeFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                    <p />
                    Aparecerá um Popup com todos os campos preenchidos dessa mesmo Administrador.
                    <p />
                    Altere os dados pretendidos e clique em  <Button danger>Guardar</Button>
                    <p />
                    O Popup irá fechar e aparecerá um notificação a confirmar que as alterações foram guardadas.


                </div>
                <Divider />

                <h5 id="#anchor-2">Filtrar Administrador</h5>
                <div>
                    <p />
                    <Space >
                        <SettingFilled style={{ fontSize: '20px', color: "#F94D5A" }} />
                        <div style={{ paddingTop: 3 }}>Definições {'>'} Administradores {'>'} Consultar</div>
                    </Space>

                    <p />
                    Pesquisar por Administrador:
                    <p />
                    <Input style={{ width: 300 }} disabled /> <FilterFilled style={{ fontSize: '25px', color: "#F94D5A" }} />
                    <p />Insira o nome do Administrador na barra de pesquisa carregue no ícone de filtro.

                </div>

            </>)
            case 8: return (<>
                <h5 id='#8'>Efetuar Logout</h5>
                <p />
                O seu nome {'>'} <LogoutOutlined style={{  color: "#F94D5A", }} /> Logout

            </>)

            default: return ("Selecione uma opção.")

        }

    }


    render() {

        return (
            <div  >
                <Layout className="site-layout-background"  >
                    <Sider className="site-layout-background" width={250}    >
                        <Affix offsetTop={10}>
                            <Menu mode="inline" style={{ height: '100%' }}      >
                                <Menu.Item key="1" onClick={() => { this.alt(1) }}>Proposta</Menu.Item>
                                <Menu.Item key="9" onClick={() => this.alt(9)}> Cliente</Menu.Item>


                                {this.state.login === 'Super_Admin' ? 
                                <>
                                <Menu.Item key="4" onClick={() => this.alt(4)}>Tarifário</Menu.Item>

<Menu.Item key="6" onClick={() => this.alt(6)}>Utilizador</Menu.Item>
<Menu.Item key="7" onClick={() => this.alt(7)}>Administrador</Menu.Item>

                                </>
                                
                                
                                :''}

                              
                                <Menu.Item key="3" onClick={() => this.alt(3)}>Obter os dados mais recentes</Menu.Item>

                                <Menu.Item key="5" onClick={() => this.alt(5)}>Alterar os seus dados</Menu.Item>

                                <Menu.Item key="8" onClick={() => this.alt(8)}>Efetuar Logout</Menu.Item>
                            </Menu>
                        </Affix>
                    </Sider>
                    <Content style={{ paddingTop: 25, paddingLeft: 25, fontSize: 15 }} >
                        {this.dashboard()}



                    </Content>
                </Layout>



            </div >
        )
    }

}

export default Help;

