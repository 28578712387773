import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PersistedStore from './store'
import { Provider } from 'react-redux'

/**
*  Rendering of the App using Redux store
*  @param store PersistedStore Reduz's store.
*  @param Component App App to be rendered
*/
ReactDOM.render(
  <Provider store={PersistedStore.getDefaultStore().store}>
   <React.StrictMode>

      <App />
   </React.StrictMode>


  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
