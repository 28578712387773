/** Export constante for all API and to the App **/
//export const API_URL_PROPOSTAS = "https://www.konnect.tectank.pt:8000/api/proposta/";
//export const API_URL_PLANOS = "https://www.konnect.tectank.pt:8000/api/planos/";
//export const API_URL_TARIFARIO = "https://www.konnect.tectank.pt:8000/api/tarifario/";
//export const API_URL_CLIENTES = "https://www.konnect.tectank.pt:8000/api/clientes/";
//export const API_URL_ADMIN = "https://www.konnect.tectank.pt:8000/api/admin_user/";
//export const API_URL_SADMIN = "https://www.konnect.tectank.pt:8000/api/sadmin/";
//export const API_URL_USERS = "https://www.konnect.tectank.pt:8000/api/users/";
//export const API_URL_PRODUTO = "https://www.konnect.tectank.pt:8000/api/produto/";

export const API_URL_PROPOSTAS = "https://konnect.tectank.pt:8080/api/proposta/";
export const API_URL_PLANOS = "https://konnect.tectank.pt:8080/api/planos/";
export const API_URL_TARIFARIO = "https://konnect.tectank.pt:8080/api/tarifario/";
export const API_URL_CLIENTES = "https://konnect.tectank.pt:8080/api/clientes/";
export const API_URL_ADMIN = "https://konnect.tectank.pt:8080/api/admin_user/";
export const API_URL_SADMIN = "https://konnect.tectank.pt:8080/api/sadmin/";
export const API_URL_USERS = "https://konnect.tectank.pt:8080/api/users/";
export const API_URL_PRODUTO = "https://konnect.tectank.pt:8080/api/produto/";

/** Export constante for all API and to the App **/
//export const API_URL_PDF = "https://www.konnect.tectank.pt:8000/pdf";
export const API_URL_PDF = "https://konnect.tectank.pt:8080/pdf";

/** API for sending email **/
//export const API_URL_EMAIL = "https://www.konnect.tectank.pt:8000/email";
export const API_URL_EMAIL = "https://konnect.tectank.pt:8080/email";

// Email Administrador
export const API_URL_EMAIL_ADMIN = "https://konnect.tectank.pt:8080/api/admin_user/email/";
//export const API_URL_EMAIL_ADMIN = "https://www.konnect.tectank.pt:8000/api/admin_user/email/";

// Email Utilizador
export const API_URL_EMAIL_USER = "https://konnect.tectank.pt:8080/api/users/email/";
//export const API_URL_EMAIL_USER = "https://www.konnect.tectank.pt:8000/api/users/email/";

/** API call for sending notification email **/
//export const API_URL_NOTIFICACAO = "https://www.konnect.tectank.pt:8000/notificacao";
export const API_URL_NOTIFICACAO = "https://konnect.tectank.pt:8080/notificacao";

/** API call for login **/
//export const API_URL_LOGIN = "https://www.konnect.tectank.pt:8000/auth/login/";
export const API_URL_LOGIN = "https://konnect.tectank.pt:8080/auth/login/";
