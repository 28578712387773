import React, { Component, useState } from "react";
import "../css/base.css";
import axios from "axios";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Popover,
  notification,
  Popconfirm,
  Space,
} from "antd";
import PersistedStore from "../store";
import {
  API_URL_TARIFARIO,
  API_URL_PROPOSTAS,
  API_URL_CLIENTES,
  API_URL_EMAIL,
  API_URL_PDF,
  API_URL_NOTIFICACAO,
  API_URL_PLANOS,
  API_URL_ADMIN,
} from "../constants";
import { v4 as uuidv4 } from "uuid";
import { PlusCircleFilled, CloseOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;
const { uuid } = require("uuidv4");

class EditProposta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      id: 0,
      name: "",
      email: "",
      telemovel: 0,
      morada: "",
      obs: "",
      nif: 0,
      tarifarios: [],
      clientes: [],
      valor: "",
      tarifario: [],
      client_id: 0,
      responsavel: "",
      id_plano: "",
      status: "EM APROVACAO",
      fidelizacao: "",
      servico: "",
      valor2: "",
      planos: [
        {
          id: "0",
          tarifario: "",
          fidelizacao: "",
          valor: "",
          valor2: true,
          servico: "",
        },
      ],
      id_tarifario: "0",
      tarifariosFinal: [],
      idsLinhas: [],
      nTotal: 0,
      tamanhoTar: 0,
    };
  }

  /**
   * Get data from API
   */
  componentDidMount() {
    axios.get(API_URL_TARIFARIO).then((res) => {
      var data = res.data;
      this.setState({ tarifarios: data });
    });

    axios.get(API_URL_CLIENTES).then((res) => {
      var data = res.data;
      this.setState({ clientes: data });
      if (this.state.id > 0) {
        //Load client in the form
        let d = data.filter((p) => this.state.client_id === p.id).pop();
        this.setState({
          name: d.name,
          email: d.email,
          telemovel: d.telemovel,
          morada: d.morada,
          nif: d.nif,
          clientes: data,
        });
      }
    });

    if (this.props.data) {
      const {
        id,
        obs,
        nif,
        valor,
        valor2,
        tarifario,
        client_id,
        fidelizacao,
        servico,
        responsavel,
        status,
      } = this.props.data;

      let arrayPlanos = [];
      let idsLinhas = [];

      axios.get(API_URL_PLANOS).then((res) => {
        var data = res.data;
        data.forEach((element) => {
          if (element.id_plano == this.props.data.id_plano) {
            const plano = {
              id: element.id + "",
              tarifario: element.tarifario,
              fidelizacao: element.fidelizacao,
              valor: element.valor + "",
              valor2: element.valor2,
              servico: element.servico,
            };
            arrayPlanos.push(plano);

            const idLinha = {
              id: element.id,
            };
            idsLinhas.push(idLinha);
          }
        });
        this.setState({ planos: arrayPlanos });
      });
      this.state.planos = arrayPlanos;
      this.state.nTotal = arrayPlanos.length;
      this.state.id_plano = this.props.data.id_plano;
      this.state.idsLinhas = idsLinhas;
      this.state.id_tarifario = "0";
      this.state.tarifario = "";
      this.state.fidelizacao = "";
      this.state.valor = "";
      this.state.servico = "";
      this.state.valor2 = "0";
      this.forceUpdate();
      this.setState({
        id,
        obs,
        nif,
        valor,
        valor2,
        tarifario,
        client_id,
        fidelizacao,
        servico,
        responsavel,
        status,
        tar: tarifario,
      });
    }
    this.data();
  }

  /**
   * Get data from the Redux store */
  data() {
    const store = PersistedStore.getDefaultStore().store;
    const vale = store.getState().loginState;
    this.setState({ login: vale.name });
  }

  /**
   * Updates the variable tarifario in state contain the selected tarifarios and total value accordingly
   *  */
  onTarifario = (e) => {
    let p = this.state.tarifarios;
    let servico = toString(e);
    let preco = 0;
    let name = "";
    // let i = 0
    // let j = 0
    // while (servico.length > i) {
    //     for (j = 0; p.length > j; j++) {
    //         if (p[j].id === parseInt(servico[i])) {
    //             preco = preco + p[j].valor
    //         }
    //     }
    //     i++
    // }
    this.state.tarifarios.forEach((element) => {
      if (element.id == e) {
        preco = element.valor;
        name = element.name;
      }
    });
    this.setState({
      tarifario: JSON.stringify(e),
      servico_id: servico,
      valor: preco,
      valor2: preco,
    });
    this.state.tarifario = { value: e, label: name };
    this.state.tamanhoTar = this.state.tarifario.length;
    // Caso o valor seja 0, limpamos a variável se modo a obrigar o utilizador a colocar um valor, evitando que a fatura vá com 0 euros
    if (preco == 0) {
      this.setState({ valor: "" });
    }
  };

  /**
   * Updates the variable fidelizacao in state
   *  */
  onFidelizacao = (e) => {
    this.setState({ fidelizacao: e });
  };

  /**
   * Updates the variable valor of the client in state
   *   @param  {event} e
   * */
  onValor = (e) => {
    this.setState({ valor: e.target.value });
  };

  /**
   * Updates the variable servico of the client in state
   *   @param  {event} e
   * */
  onServico = (e) => {
    this.setState({ servico: e.target.value });
  };

  /**
   * Updates the variable name of the client in state
   *   @param  {event} e
   * */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };

  /**
   * Updates the variable email of the client in state
   * @param  {event} e
   */
  onEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   *  Updates the variable telemovel of the client in state
   * @param  {event} e
   *
   */
  onTel = (e) => {
    this.setState({ telemovel: e.target.value });
  };
  /**
   * Updates the variable morada of the client in state
   * @param  {event} e
   */
  onMorada = (e) => {
    this.setState({ morada: e.target.value });
  };
  /**
   * Updates the variable nif of the client in state
   * @param  {event} e
   */
  onNIF = (e) => {
    this.setState({ nif: e.target.value });
  };
  /**
   * Updates the variable obs of the client in state
   * @param  {event} e
   */
  onObs = (e) => {
    this.setState({ obs: e.target.value });
  };

  /**
   *
   * Updates the variable responsavel of the client in state
   * @param  {event} e
   */
  onResponsavel = (e) => {
    this.setState({ responsavel: e.target.value });
  };

  /**
   * Edit informations of Proposta and send for aproval or the client depending on the user logged in
   * @param {*} e
   */
  edit = async (e) => {
    if (this.state.login === "User") {
      //Eliminar linhas plano de proposta
      this.state.idsLinhas.forEach((element) => {
        axios.delete(API_URL_PLANOS + element.id).then((res) => {});
      });

      //Inserir planos proposta
      if (1 >= this.state.planos.length <= 4) {
        for (const element of this.state.planos) {
          if (element.id != "0") {
            const plano = {
              id_plano: this.state.id_plano,
              tarifario: element.tarifario,
              fidelizacao: element.fidelizacao,
              servico: element.servico,
              valor: element.valor,
              valor2: element.valor2,
            };
            await axios.post(API_URL_PLANOS, plano);
          }
        }
      }
      this.state.tarifarios = [];
      this.state.tarifariosFinal = [];

      if (
        this.state.tarifario != "" &&
        this.state.fidelizacao != "" &&
        this.state.valor != "" &&
        this.state.servico != "" &&
        this.state.tarifario != undefined &&
        this.state.fidelizacao != undefined &&
        this.state.valor != undefined &&
        this.state.servico != undefined
      ) {
        //Valor2
        let editarValor = false;
        this.state.tarifarios.forEach((element) => {
          if (element.id == this.state.tarifario) {
            if (element.valor == 0) {
              editarValor = true;
            }
          }
        });
        const plano = {
          id_plano: this.state.id_plano,
          tarifario: this.state.tarifario,
          fidelizacao: this.state.fidelizacao,
          servico: this.state.servico,
          valor: this.state.valor,
          valor2: editarValor,
        };
        await axios.post(API_URL_PLANOS, plano);
      }

      const { clientes, tarifarios, tarifariosFinal, ...novoNome } = this.state;
      axios.put(API_URL_PROPOSTAS + this.state.id, novoNome).then((res) => {
        axios
          .put(API_URL_CLIENTES + this.state.client_id, novoNome)
          .then((res) => {
            this.openNotification(
              "Proposta atualizada enviada para aprovação."
            );
            this.props.resetState();
            this.props.toggle();
            axios.post(API_URL_NOTIFICACAO);
          })
          .catch((err) => {
            if (
              err.response.data.nif == "clientes with this nif already exists."
            ) {
              this.openNotification("Já existe um cliente com o mesmo NIF!");
            }
          });
      });
    } else {
      //Eliminar linhas plano de proposta
      this.state.idsLinhas.forEach((element) => {
        axios.delete(API_URL_PLANOS + element.id).then((res) => {});
      });

      //Inserir planos proposta
      if (1 >= this.state.planos.length <= 4) {
        for (const element of this.state.planos) {
          if (element.id != "0") {
            const plano = {
              id_plano: this.state.id_plano,
              tarifario: element.tarifario,
              fidelizacao: element.fidelizacao,
              servico: element.servico,
              valor: element.valor,
              valor2: element.valor2,
            };
            await axios.post(API_URL_PLANOS, plano);
          }
        }
      }
      this.state.tarifarios = [];
      this.state.tarifariosFinal = [];
      if (
        this.state.tarifario != "" &&
        this.state.fidelizacao != "" &&
        this.state.valor != "" &&
        this.state.servico != "" &&
        this.state.tarifario != undefined &&
        this.state.fidelizacao != undefined &&
        this.state.valor != undefined &&
        this.state.servico != undefined
      ) {
        //Valor2
        let editarValor = false;
        this.state.tarifarios.forEach((element) => {
          if (element.id == this.state.tarifario) {
            if (element.valor == 0) {
              editarValor = true;
            }
          }
        });
        const plano = {
          id_plano: this.state.id_plano,
          tarifario: this.state.tarifario,
          fidelizacao: this.state.fidelizacao,
          servico: this.state.servico,
          valor: this.state.valor,
          valor2: editarValor,
        };
        await axios.post(API_URL_PLANOS, plano);
      }

      const { clientes, tarifarios, tarifariosFinal, ...novoNome } = this.state;
      axios.put(API_URL_PROPOSTAS + this.state.id, novoNome).then((res) => {
        axios
          .put(API_URL_CLIENTES + this.state.client_id, novoNome)
          .then((res) => {
            this.openNotification("Proposta gravada com sucesso.");
            this.props.resetState();
            this.props.toggle();
          })
          .catch((err) => {
            if (
              err.response.data.nif == "clientes with this nif already exists."
            ) {
              this.openNotification("Já existe um cliente com o mesmo NIF!");
            }
          });
      });
    }
  };

  /**
   * Send email to client with proposta
   * @param {*} e
   */
  sendClient = (e) => {
    let valor = 0;
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().userState;

    let propostaArray = [];
    let propostaHtml = "";

    axios.get(API_URL_PLANOS).then(async (res) => {
      res.data.forEach((element) => {
        if (element.id_plano == this.state.id_plano) {
          valor += element.valor;

          this.state.tarifarios.forEach((element2) => {
            if (element.tarifario == element2.id) {
              let obs = element2.obs.replaceAll("\n", "");

              const linhaProposta = {
                servico: element.servico,
                tarifarioNome: element2.name,
                tarifarioObs: obs.replaceAll(".", ".\n"),
                fidelizacao: element.fidelizacao,
                valor: element.valor,
              };

              const obsHtml = obs.replaceAll(
                ".",
                ".%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20"
              );

              propostaHtml +=
                "%20%20%20%20%20%20%20%20%20%20%20%20Serviço:%20" +
                element.servico +
                "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Tarifário:%20" +
                element2.name +
                "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                obsHtml.replaceAll(" ", "%20") +
                "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Permanência:%20" +
                element.fidelizacao +
                "%20meses%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                "Valor%20mensal:%20" +
                element.valor +
                "€%20%2B%20iva" +
                "%0D%0A%0D%0A";

              propostaArray.push(linhaProposta);
            }
          });
        }
      });
      let telemovel = "";
      if (store.getState().loginState.name == "Super_Admin") {
        telemovel = "";
      } else if (store.getState().loginState.name == "Admin_User") {
        await axios.get(API_URL_ADMIN).then((res) => {
          res.data.forEach((element) => {
            if (element.id == val.id) {
              telemovel = element.telemovel;
            }
          });
        });
      }
      axios
        .post(API_URL_EMAIL, {
          proposta: this.state.id,
          valor: valor.toFixed(2),
          utilizador: val.name,
          telemovel: telemovel,
          linhasProposta: propostaArray,
          html: propostaHtml,
        })
        .then((res) => {
          this.openNotification("Proposta enviada para o cliente");

          // let blob = new Blob([res.data], { type: 'application/pdf' }),
          //   url = window.URL.createObjectURL(blob)

          // window.open(url) // Mostly the same, I was just experimenting with dif
          this.props.resetState();
          this.props.toggle();
        })
        .catch((err) => {
          this.props.resetState();
          this.openNotification(err);
        });
    });
  };

  /**
   * Opens a notification bar with text
   * @param {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };

  /**
   * Get tarifario name
   * @param {string} e ID of tarifario
   * @returns name o tarifario
   */
  func = (e) => {
    let obj = this.state.tarifarios.filter((p) => p.id === parseInt(e));
    if (obj.length !== 0) {
      let o = obj.pop();
      if (o !== undefined) {
        if (o.name !== undefined) {
          return o.name;
        }
      }
    }
  };

  /**
   * Get id from tarifario
   * @param {string} e
   * @returns id
   */
  tari = (e) => {
    // const tarifarios = this.state.tarifarios
    // let array  = []
    // if (this.state.tarifarios.length !== 0 && e.length !== 0) {
    //         for (let i = 0; i < e.length; i++) {
    //            for (let j = 0; j < tarifarios.length; j++) {
    //                if(parseInt(e[i]) === tarifarios[j].id){
    //                    array.push(tarifarios[j].id.toString())
    //                }
    //            }
    //         }
    //     }
    //     return array
  };

  //Adicionar linha do tarifário
  adicionarLinha = (e) => {
    let condicaoArray = false;
    let condicaoArray2 = false;
    //Verificar se no array existe no mínimo um plano sem o id 0
    this.state.planos.forEach((element) => {
      if (element.id != "0") {
        condicaoArray = true;
      } else {
        condicaoArray2 = !condicaoArray2;
      }
    });

    if (
      (this.state.tarifario != "" &&
        this.state.fidelizacao != "" &&
        this.state.valor != "" &&
        this.state.servico != "") ||
      condicaoArray
    ) {
      //Quando a array apenas contém uma linha preenchida, linha da qual não tem o id 0, ou seja, as variáveis estão vazias
      if (
        (condicaoArray &&
          this.state.tarifario == "" &&
          this.state.fidelizacao == "" &&
          this.state.valor == "" &&
          this.state.servico == "" &&
          !condicaoArray2) ||
        (condicaoArray &&
          this.state.tarifario == undefined &&
          this.state.fidelizacao == undefined &&
          this.state.valor == undefined &&
          this.state.servico == undefined &&
          !condicaoArray2)
      ) {
        let camposVazios = false;

        //Verificar se a linha tem todos os campos preenchidos
        this.state.planos.forEach((element) => {
          if (
            element.tarifario == "" ||
            element.fidelizacao == "" ||
            element.valor == "" ||
            element.servico == ""
          ) {
            camposVazios = true;
          }
        });

        if (!camposVazios) {
          //Adicionar array com linha vazia
          const arrayLinhaVazia = {
            id: "0",
            tarifario: "",
            fidelizacao: "",
            valor: "",
            valor2: true,
            servico: "",
          };

          let editarValor = false;
          //Verificar se o tarifário já tem um preço pré-definido
          this.state.tarifarios.forEach((element) => {
            if (element.id == this.state.tarifario) {
              if (element.valor == 0) {
                editarValor = true;
              }
            }
          });

          let arrayFinal = [];
          //Ir buscar as linhas já existentes
          this.state.planos.forEach((element) => {
            const linha = {
              id: element.id,
              tarifario: element.tarifario,
              fidelizacao: element.fidelizacao,
              valor: element.valor,
              valor2: editarValor,
              servico: element.servico,
            };
            arrayFinal.push(linha);
          });

          arrayFinal.push(arrayLinhaVazia);

          this.state.planos = arrayFinal;
          this.state.nTotal = arrayFinal.length;
          this.state.tarifario = "";
          this.state.fidelizacao = "";
          this.state.servico = "";
          this.state.valor = "";
          this.state.valor2 = "0";
          this.forceUpdate();
        } else {
          alert("Preencha todos os dados da linha!");
        }
      } else {
        if (
          condicaoArray &&
          (this.state.tarifario == "" ||
            this.state.fidelizacao == "" ||
            this.state.valor == "" ||
            this.state.servico == "")
        ) {
          alert("Preencha todos os dados da linha!");
        } else {
          //Adicionar array com linha vazia
          const arrayLinhaVazia = {
            id: "0",
            tarifario: "",
            fidelizacao: "",
            valor: "",
            valor2: true,
            servico: "",
          };

          //A variável tarifário aparecia ""1""", foi utilizado a função replace 2 vezes de modo a substituir dois dos " por 0
          let str = this.state.tarifario;
          let newStr = str.replace('"', "");
          let newStr2 = newStr.replace('"', "");

          let editarValor = false;
          //Verificar se o tarifário já tem um preço pré-definido
          this.state.tarifarios.forEach((element) => {
            if (element.id == this.state.tarifario) {
              if (element.valor == 0) {
                editarValor = true;
              }
            }
          });

          //Adicionar linha preenchida
          const uuid4 = uuidv4();
          const arrayLinhaNova = {
            id: uuid4,
            tarifario: newStr2,
            fidelizacao: this.state.fidelizacao,
            valor: this.state.valor,
            valor2: editarValor,
            servico: this.state.servico,
          };

          //Ir buscar as linhas já existentes
          let linhasExistentes = [];
          this.state.planos.forEach((element) => {
            if (element.id != 0 && element.id != uuid4) {
              const linha = {
                id: element.id,
                tarifario: element.tarifario,
                fidelizacao: element.fidelizacao,
                valor: element.valor,
                valor2: element.valor2,
                servico: element.servico,
              };
              linhasExistentes.push(linha);
            }
          });

          //Adicionar ao array: linhas existentes, linha nova, linha vazia
          if (linhasExistentes.length == 0) {
            this.state.planos.length = 0;
            let arrayFinal = [];
            arrayFinal.push(arrayLinhaNova, arrayLinhaVazia);
            this.state.planos = arrayFinal;
          } else {
            let arrayFinal = [];
            this.state.planos.forEach((element) => {
              if (element.id != 0 && element.id != uuid4) {
                const linha = {
                  id: element.id,
                  tarifario: element.tarifario,
                  fidelizacao: element.fidelizacao,
                  valor: element.valor,
                  valor2: element.valor2,
                  servico: element.servico,
                };
                arrayFinal.push(linha);
              }
            });
            arrayFinal.push(arrayLinhaNova, arrayLinhaVazia);
            this.state.planos.length = 0;
            this.state.planos = arrayFinal;
            this.state.nTotal = arrayFinal.length;
          }

          //Limpar variáveis
          this.state.id_tarifario = "0";
          this.state.tarifario = "";
          this.state.fidelizacao = "";
          this.state.valor = "";
          this.state.servico = "";
          this.state.valor2 = "0";

          this.forceUpdate();
        }
      }
    } else {
      alert("Preencha todos os dados da linha!");
    }
  };

  //Eliminar linha do tarifário
  eliminarLinha = (e) => {
    let novoArrayPlanos = [];
    this.state.planos.forEach((element) => {
      if (element.id != e) {
        const plano = {
          id: element.id,
          tarifario: element.tarifario,
          fidelizacao: element.fidelizacao,
          valor: element.valor,
          valor2: element.valor2,
          servico: element.servico,
        };
        novoArrayPlanos.push(plano);
      }
    });
    //Caso o id seja 0, significa que temos que colocar as variáveis vazias
    if (e == "0") {
      this.state.tarifario = [];
      this.state.fidelizacao = "";
      this.state.valor = "";
      this.state.valor2 = "0";
      this.state.servico = "";
    }
    //Caso o id da linha a ser eliminado seja
    //Escrever novo array de planos
    this.state.planos = [];
    this.forceUpdate();
    this.state.planos = novoArrayPlanos;
    // this.state.nTotal = novoArrayPlanos.length;
    this.forceUpdate();
  };

  //Editar linha do tarifário
  editarLinha = (id, tarifario, fidelizacao, valor, valor2, servico) => {
    //Atribuir o preco do tarifário à variável valor2
    // let preco = 0;
    // this.state.tarifarios.forEach(element => {
    //     if(element.id == parseInt(tarifario)){
    //         preco = element.valor;
    //     }

    // });
    // this.state.valor2 = preco;
    let arrayEditado = [];
    this.state.planos.forEach((element) => {
      if (element.id == id) {
        //Se o tarifário for atualizado, é necessário atualizar as variáveis valor e valor2
        let preco = 0;
        let editarPreco = false;
        this.state.tarifarios.forEach((element) => {
          if (element.id == tarifario) {
            if (element.valor == 0) {
              editarPreco = true;
              //Verificar se o tarifário foi alterado, caso seja o preco fica vazio
              this.state.planos.forEach((element) => {
                if (element.id == id) {
                  if (element.tarifario == tarifario) {
                    preco = valor;
                  } else {
                    preco = "";
                  }
                }
              });
            } else {
              preco = element.valor;
            }
          }
        });

        const plano = {
          id: id,
          tarifario: tarifario,
          fidelizacao: fidelizacao,
          valor: preco,
          valor2: editarPreco,
          servico: servico,
        };
        arrayEditado.push(plano);
      } else {
        const plano = {
          id: element.id,
          tarifario: element.tarifario,
          fidelizacao: element.fidelizacao,
          valor: element.valor,
          valor2: element.valor2,
          servico: element.servico,
        };
        arrayEditado.push(plano);
      }
    });
    this.state.planos.length = 0;
    this.state.planos = arrayEditado;
    this.forceUpdate();
  };

  atualizarPreco = (id) => {
    let preco = 0;
    this.state.tarifarios.forEach((element) => {
      if (element.id == parseInt(id)) {
        preco = element.valor;
      }
    });
    this.state.valor2 = preco;
  };

  render() {
    const tarifario = this.state.tarifarios;
    const tarif = this.state.tarifario;

    let tarifarios = [];
    this.state.tarifarios.forEach((element) => {
      const tar = {
        value: element.id,
        label: element.name,
      };
      tarifarios.push(tar);
    });
    this.state.tarifariosFinal = tarifarios;

    return (
      <div>
        <Row className="list_nova_proposta">
          <h5>Proposta</h5>
        </Row>

        <div className="list_nova_proposta">
          <Form
            layout="vertical"
            onFinish={this.edit}
            fields={[
              {
                name: ["nif"],
                value: this.state.nif,
              },
              {
                name: ["email"],
                value: this.state.email,
              },

              {
                name: ["telemovel"],
                value: this.state.telemovel,
              },
            ]}
          >
            <h5>Dados Pessoais</h5>
            <Row gutter={[30, 30]}>
              <Col span={8}>
                <Form.Item label="Cliente:" required>
                  <Input
                    type="text"
                    required
                    value={this.state.name}
                    onChange={this.onNome}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="nif"
                  label="NIF:"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(
                        "[1,2,3,5][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"
                      ),
                      message: "Verifique o NIF.",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    minLength={9}
                    maxLength={9}
                    name="nif"
                    onChange={this.onNIF}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="email" label="Email:" required>
                  <Input
                    type="email"
                    name="email"
                    required
                    onChange={this.onEmail}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[30, 30]}>
              <Col span={16}>
                <Form.Item for="morada" label="Morada:">
                  <Input
                    type="text"
                    value={this.state.morada}
                    onChange={this.onMorada}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="telemovel"
                  label="Telemóvel:"
                  rules={[
                    {
                      required: "true",
                      pattern: new RegExp(
                        "(9[1236][0-9]) ?([0-9]{3}) ?([0-9]{3})"
                      ),
                      message: "Verifique o numero de Telemóvel.",
                    },
                  ]}
                >
                  <Input
                    type="tel"
                    name="telemovel"
                    minLength={9}
                    maxLength={9}
                    value={this.state.telemovel}
                    onChange={this.onTel}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[30, 30]}>
              <Col span={16}>
                <Form.Item for="responsavel" required label="Responsável:">
                  <Input
                    type="text"
                    required
                    value={this.state.responsavel}
                    onChange={this.onResponsavel}
                  />
                </Form.Item>
              </Col>
            </Row>

            <hr />
            <h5>Plano de proposta</h5>
            {this.state.planos.map((item) => (
              <>
                {item.id != "0" ? (
                  <Row gutter={[40, 40]}>
                    <Col span={this.state.planos.length > 1 ? 5 : 6}>
                      <Form.Item label="Tarifário:" required>
                        <Select
                          id="tarifario"
                          value={item.tarifario}
                          onChange={(e) => {
                            this.editarLinha(
                              item.id,
                              e,
                              item.fidelizacao,
                              item.valor,
                              item.valor2,
                              item.servico
                            );
                          }}
                        >
                          {!tarifario || tarifario.length <= 0 ? (
                            <Option key="0">Sem dados!</Option>
                          ) : (
                            tarifario.map((l) => (
                              <Option key={l.id}>{l.name}</Option>
                            ))
                          )}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={this.state.planos.length > 1 ? 5 : 6}>
                      <Form.Item label="Fidelização:" required>
                        <Select
                          id="fidelizacao"
                          onChange={(e) => {
                            this.editarLinha(
                              item.id,
                              item.tarifario,
                              e,
                              item.valor,
                              item.valor2,
                              item.servico
                            );
                          }}
                          value={item.fidelizacao}
                        >
                          <Option key="24">24 meses</Option>
                          <Option key="30">30 meses</Option>
                          <Option key="36">36 meses</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {item.tarifario.length != 0 && !item.valor2 ? (
                      <Col span={this.state.planos.length > 1 ? 5 : 6}>
                        <Form.Item label="Valor:" required>
                          <Input type="text" value={item.valor} required />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col span={this.state.planos.length > 1 ? 5 : 6}>
                        <Form.Item label="Valor:" required>
                          <Input
                            type="text"
                            required
                            placeholder="Insira um valor"
                            value={item.valor}
                            onChange={(e) => {
                              this.editarLinha(
                                item.id,
                                item.tarifario,
                                item.fidelizacao,
                                e.target.value,
                                item.valor2,
                                item.servico
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={this.state.planos.length > 1 ? 5 : 6}>
                      <Form.Item label="Serviço:" required>
                        <Input
                          required
                          type="text"
                          value={item.servico}
                          onChange={(e) => {
                            this.editarLinha(
                              item.id,
                              item.tarifario,
                              item.fidelizacao,
                              item.valor,
                              item.valor2,
                              e.target.value
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>

                    {this.state.planos.length > 1 && (
                      <Col span={3} style={{ textAlign: "center" }}>
                        <Form.Item label=" ">
                          <CloseOutlined
                            style={{
                              fontSize: "20px",
                              color: "#F94D5A",
                              cursor: "pointer",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              this.eliminarLinha(item.id);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <Row gutter={[40, 40]}>
                    <Col span={this.state.planos.length > 1 ? 5 : 6}>
                      <Form.Item label="Tarifário:" required>
                        <Select
                          id="tarifario"
                          placeholder="Selecionar Tarifário"
                          onChange={this.onTarifario}
                          options={this.state.tarifariosFinal}
                        >
                          {/* {(!this.state.tarifarios || this.state.tarifarios.length <= 0) ? (<Option key="0">Sem dados!</Option>) : (
                                                            this.state.tarifarios.map(l => (<Option key={l.id}>{l.name}</Option>)))} */}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={this.state.planos.length > 1 ? 5 : 6}>
                      <Form.Item
                        label="Fidelização:"
                        name="fidelizacao"
                        required
                      >
                        <Select
                          id="fidelizacao"
                          onChange={this.onFidelizacao}
                          name="fidelizacao"
                          aria-required
                          value={this.state.fidelizacao}
                        >
                          <Option key="24">24 meses</Option>
                          <Option key="30">30 meses</Option>
                          <Option key="36">36 meses</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {(this.state.tamanhoTar == 0 && !item.valor2) ||
                    this.state.valor2 != "0" ? (
                      <Col span={this.state.planos.length > 1 ? 5 : 6}>
                        <Form.Item label="Valor:" required>
                          <Input
                            type="text"
                            value={this.state.valor}
                            placeholder="Insira um valor"
                            required
                          />
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col span={this.state.planos.length > 1 ? 5 : 6}>
                        <Form.Item label="Valor:" required>
                          <Input
                            type="text"
                            required
                            placeholder="Insira um valor"
                            value={this.state.valor}
                            onChange={this.onValor}
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={this.state.planos.length > 1 ? 5 : 6}>
                      <Form.Item label="Serviço:" required>
                        <Input
                          type="text"
                          required
                          value={this.state.servico}
                          onChange={this.onServico}
                        />
                      </Form.Item>
                    </Col>
                    {this.state.planos.length > 1 && (
                      <Col span={3} style={{ textAlign: "center" }}>
                        <Form.Item label=" ">
                          <CloseOutlined
                            style={{
                              fontSize: "20px",
                              color: "#F94D5A",
                              cursor: "pointer",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              this.eliminarLinha(item.id);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}
              </>
            ))}
            <Space style={{ paddingTop: 0, paddingBottom: 7 }}>
              <PlusCircleFilled
                style={{
                  fontSize: "20px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
                onClick={this.adicionarLinha}
              />

              <h6 style={{ paddingTop: 7 }}>Adicionar tarifário</h6>
            </Space>
            <Form.Item for="obs" label="Obs">
              <TextArea rows={4} value={this.state.obs} onChange={this.onObs} />
            </Form.Item>
            <Form.Item style={{ alignText: "center", textAlign: "right" }}>
              <Button danger htmlType="submit">
                {this.state.login === "User"
                  ? "Enviar para aprovação"
                  : "Gravar"}
              </Button>
            </Form.Item>
          </Form>
          {this.state.login !== "User" ? (
            this.state.status !== "APROVADO" ? (
              <div style={{ right: 0, textAlign: "right" }}>
                <Button
                  style={{ right: 0, textAlign: "right" }}
                  onClick={this.sendClient}
                >
                  {" "}
                  Enviar para o cliente{" "}
                </Button>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default EditProposta;
