/**
 * Varaibles from Redux store(actions)
 */
export const USER_LOGIN = 'USER_LOGIN';
export const USER_RESET = 'USER_RESET';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const NEW_PROPOSTA = 'NEW_PROPOSTA';
export const PROPOSTA_RESET = 'PROPOSTA_RESET';
