import React, { Component, Fragment } from "react";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import { Button, notification } from "antd";
import axios from "axios";
import { API_URL_USERS, API_URL_ADMIN, API_URL_PROPOSTAS, API_URL_TARIFARIO, API_URL_CLIENTES } from "../constants";
import { DeleteFilled } from '@ant-design/icons';

class DeleteModal extends Component {
  state = {
    modal: false
  };

  /**
   * Manages if modal is open
   */
  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  /**
   * API call DELETE to delete selected data
   * @param {int} data 
   */
  delete = data => {

    let info = this.props.info;
    let url = "";

    if (info === "users") {
      url = API_URL_USERS;
    }
    if (info === "admin") {
      url = API_URL_ADMIN;
    }
    if (info === "propostas") {
      url = API_URL_PROPOSTAS;
    }
    if (info === "tar") {
      url = API_URL_TARIFARIO;
    } if (info === "clientes") {
      url = API_URL_CLIENTES;
    }
    

  
    axios.delete(url + data).then(() => {
      this.openNotification("O campo foi apagado.")
      this.props.resetState();
      this.toggle();
    });
  };


  /**
   * Notification components
   * Appears in a box
   * @param  {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: 'Notificação',
      duration: 1.5,
      description:
        texto,

    });
  };

  render() {
    return (
      <Fragment>

        <DeleteFilled style={{ fontSize: '25px', color: "#F94D5A" }} onClick={() => this.toggle()} />
        <Modal isOpen={this.state.modal} toggle={this.toggle}>

          <ModalBody>
            Deseja apagar ?
          </ModalBody>

          <ModalFooter>
            <Button shape="round" type="button" onClick={() => this.toggle()}>Cancelar</Button>
            <Button shape="round" type="button" color="primary" onClick={() => this.delete(this.props.id)}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
export default DeleteModal;