import React, { Component } from "react";
import {
  Layout,
  Menu,
  Avatar,
  Form,
  Input,
  Button,
  notification,
  Row,
  Col,
} from "antd";
import "../css/base.css";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import PersistedStore from "../store";
import { Navigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { loginReset, userReset, updateData } from "../actions";
import { connect } from "react-redux";
import Inicio from "./inicio";
import Estatisticas from "./estatisticas";
import Listagem from "./list";
import axios from "axios";
import { API_URL_ADMIN } from "../constants";
import Help from "./help";
import NewAdmin from "./NewAdmin";
import NewUser from "./NewUser";
import NewTarifario from "./newTarifario";
const { SubMenu } = Menu;
const { Content } = Layout;
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dash: "dash",
      id: "",
      name: "",
      email: "",
      password: "",
      img: "",
      logout: false,
    };
  }

  /**
   * First Component to render */
  componentDidMount() {
    this.data();
  }
  /**
   * Get data from Redux store */
  data() {
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().userState;
    const login = store.getState().loginState;

    this.setState({
      login: login.name,
      id: val.id,
      email: val.email,
      name: val.name,
      img: val.img,
    });
  }
  /**
   * Performs logout of the user */
  logout = (e) => {
    this.props.loginReset();
    this.props.userReset();
    this.setState({ logout: true });
  };

  /**
   * Updates dash variable in state
   * @param string dash Dashboar variable
   * */
  alt(dash) {
    this.setState({ dash: dash });
  }

  /**
   * Gets Listagem Component to list data
   * @Component Listagem
   * @param string info Type of data to be displayed
   * */
  listagem(info) {
    return <Listagem info={info} />;
  }

  /**
   * Alternate between different components in dashboard
   */
  dashboard() {
    let dash = this.state.dash;

    switch (dash) {
      case "dash":
        return <Inicio info="dash" tipo="admin" />;
      case "propostas":
        return this.listagem("propostas");
      case "clientes":
        return this.listagem("clientes");
      case "Ajuda":
        return <Help />;
      case "perfil":
        return this.perfil();
      case "estatisticas":
        return <Estatisticas />;
      case "add_admin": {
        return (
          <div>
            <Button onClick={() => this.alt("inicio")}>Voltar</Button>
            <NewAdmin />
          </div>
        );
      }
      case "add_user": {
        return (
          <div>
            <Button onClick={() => this.alt("inicio")}>Voltar</Button>
            <NewUser />
          </div>
        );
      }
      case "add_tar": {
        return (
          <div>
            <Button onClick={() => this.alt("inicio")}>Voltar</Button>
            <NewTarifario />
          </div>
        );
      }
      case "list_admin":
        return this.listagem("admin");
      case "list_user":
        return this.listagem("users");
      case "list_tar":
        return this.listagem("tar");
      default:
        return <Inicio info="dash" tipo="sadmin" />;
    }
  }

  /**
   * Updates state email variable
   * @param {string} value
   */
  onEmail = (value) => {
    this.setState({ email: value });
  };

  /**
   * Updates state password variable
   * @param {string} e
   */
  onPass = (e) => {
    const hashedPassword = bcrypt.hashSync(
      e.target.value,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    this.setState({ password: hashedPassword });
  };

  /**
   * Updates state nome variable
   * @param {string} value
   */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };

  /**
   * Makes PUT API call to put the changes
   * @param {string} e
   */
  edit = () => {
    axios.put(API_URL_ADMIN + this.state.id, this.state).then(() => {
      this.openNotification("Os dados foram atualizados com sucesso!");
    });
  };

  /**
   * Edit account information of the user
   */
  perfil() {
    return (
      <Row style={{ paddingTop: 50 }} className="row justify-content-center ">
        <Col></Col>
        <Col span={7}>
          <div>
            <h4>Perfil</h4>
          </div>
          <Form
            style={{ paddingTop: 25 }}
            layout="vertical"
            onFinish={this.edit}
          >
            <Form.Item label="Nome" name="nome">
              <Input
                type="text"
                name="name"
                onChange={this.onNome}
                defaultValue={this.state.name}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  pattern: new RegExp("[a-z.]*[@]tectank.pt"),
                  message: "Formato de email incorreto.",
                },
              ]}
            >
              <Input
                type="email"
                onChange={this.onEmail}
                defaultValue={this.state.email}
              />
            </Form.Item>

            <Form.Item label="Password" name="password">
              <Input.Password onChange={this.onPass} />
            </Form.Item>

            <Form.Item style={{ textAlign: "right", right: -15 }}>
              <Button htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    );
  }

  /**
   * Open a notification bar
   * @param  {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };

  /**
   * Render components
   */
  render() {
    return (
      <div>
        {this.state.login === "" ? <Navigate to="/" /> : ""}

        <Layout>
          <Menu
            className="upbar"
            mode="horizontal"
            triggerSubMenuAction={"click"}
          >
            <Menu.Item
              key="m1"
              onClick={() => {}}
              icon={<Avatar shape="square" src={""} />}
            ></Menu.Item>
            <Menu.Item
              key="m2"
              onClick={() => {
                this.alt("dash");
              }}
            >
              {" "}
              Dashboard{" "}
            </Menu.Item>
            <Menu.Item
              key="m3"
              onClick={() => {
                this.alt("propostas");
              }}
            >
              {" "}
              Propostas{" "}
            </Menu.Item>
            <Menu.Item
              key="m4"
              onClick={() => {
                this.alt("clientes");
              }}
            >
              {" "}
              Clientes{" "}
            </Menu.Item>
            <Menu.Item
              key="m7"
              onClick={() => {
                this.alt("estatisticas");
              }}
            >
              {" "}
              Estatísticas{" "}
            </Menu.Item>
            <Menu.Item
              key="m8"
              onClick={() => {
                this.alt("Ajuda");
              }}
            >
              {" "}
              Ajuda{" "}
            </Menu.Item>

            <Menu.Item key="m14" style={{ marginLeft: "auto" }}>
              <SubMenu key="sub1" title={this.state.name}>
                <Menu.Item
                  key="subsub1"
                  icon={<UserOutlined style={{ color: "#F94D5A" }} />}
                  onClick={() => {
                    this.alt("perfil");
                  }}
                >
                  Perfil
                </Menu.Item>
                <Menu.Item
                  key="subsub2"
                  icon={<LogoutOutlined style={{ color: "#F94D5A" }} />}
                  onClick={this.logout}
                >
                  {this.state.logout === true ? <Navigate to="/" /> : "Logout"}
                </Menu.Item>
              </SubMenu>
            </Menu.Item>
          </Menu>
          <Layout className="lay">
            <Content>{this.dashboard()}</Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

/**
 * Match state to props
 * @param  {} state
 */
function mapStateToProps(state) {
  return {
    name: state.name,
    email: state.email,
    password: state.password,
  };
}
/**
 * Match functions to props
 * @param  {} dispatch
 */
function mapDispatchToProps(dispatch) {
  return {
    loginReset: () => dispatch(loginReset()),
    userReset: () => dispatch(userReset()),
    updateData: (data) => dispatch(updateData(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
//_______________________________
