import React, { Component } from "react";
import {
  Layout,
  Menu,
  Avatar,
  Form,
  Input,
  Button,
  notification,
  Row,
  Col,
} from "antd";
import "../css/base.css";
import {
  LogoutOutlined,
  UserOutlined,
  SettingTwoTone,
} from "@ant-design/icons";
import PersistedStore from "../store";
import { Navigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { loginReset, userReset, updateData } from "../actions";
import { connect } from "react-redux";
import Inicio from "./inicio";
import Estatisticas from "./estatisticas";
import Listagem from "./list";
import axios from "axios";
import { API_URL_SADMIN } from "../constants";
import Help from "./help";
import NewAdmin from "./NewAdmin";
import NewUser from "./NewUser";
import NewTarifario from "./newTarifario";

const { SubMenu } = Menu;
const { Content } = Layout;

/**
 * Class of the Super Admin(Comercial)
 * Page of the Super Admin
 */
class SuperAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dash: "dash",
      id: "",
      name: "",
      email: "",
      password: "",
      img: "",
      logout: false,
    };
  }

  /**
   * Rendering function whrn the component is mounted
   * Occurs only once
   */
  componentDidMount() {
    this.data();
  }

  /**
   * Getting information from Redux store
   *  @param store PersistedStore Redux Store
   * @param state val  State  of user in the Redux store
   * @param state login  State  of login in the Redux store
   */
  data() {
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().userState;
    const login = store.getState().loginState;

    this.setState({
      login: login.name,
      id: val.id,
      email: val.email,
      name: val.name,
      img: val.img,
    });
  }

  /**
   * Makes the logout from the App */
  logout = (e) => {
    this.props.loginReset();
    this.props.userReset();
    this.setState({ logout: true });
  };

  /**
   * Updates the variable dash
   * @param string dash Variable dash used in state
   */
  alt(dash) {
    this.setState({ dash: dash });
  }

  /**
   * Refering to Component Listagem - lists data in a table
   * @param string info Defines what to be listed
   */
  listagem(info) {
    return <Listagem info={info} inicio={info} />;
  }

  /**
   * Managing which component to be renderes based on State
   * @param string dash Variable dash used in state
   */
  dashboard() {
    let dash = this.state.dash;
    switch (dash) {
      case "dash":
        return <Inicio info="dash" tipo="sadmin" />;
      case "propostas":
        return this.listagem("propostas");
      case "clientes":
        return this.listagem("clientes");
      case "Ajuda":
        return <Help />;
      case "perfil":
        return this.perfil();
      case "estatisticas":
        return <Estatisticas />;
      case "add_admin": {
        return (
          <div>
            <Row
              style={{ paddingTop: 50 }}
              className="row justify-content-right"
            >
              <Col span={7}></Col>
              <Col span={7}></Col>
              <Col span={10}>
                {" "}
                <Button onClick={() => this.alt("inicio")}>Voltar</Button>
              </Col>
            </Row>
            <NewAdmin />
          </div>
        );
      }
      case "add_user": {
        return (
          <div>
            <Row
              style={{ paddingTop: 50 }}
              className="row justify-content-right"
            >
              <Col span={7}></Col>
              <Col span={7}></Col>
              <Col span={10}>
                {" "}
                <Button onClick={() => this.alt("inicio")}>Voltar</Button>
              </Col>
            </Row>
            <NewUser />
          </div>
        );
      }

      case "add_tar": {
        return (
          <div>
            <Row
              style={{ paddingTop: 50 }}
              className="row justify-content-right"
            >
              <Col span={8}></Col>
              <Col span={8}></Col>
              <Col span={8}>
                {" "}
                <Button onClick={() => this.alt("inicio")}>Voltar</Button>
              </Col>
            </Row>

            <NewTarifario />
          </div>
        );
      }
      case "list_admin":
        return this.listagem("admin");
      case "list_user":
        return this.listagem("users");
      case "list_tar":
        return this.listagem("tar");
      default:
        return <Inicio info="dash" tipo="sadmin" />;
    }
  }

  /**
   * Updates the variable email in state
   * @param  {event} e
   */
  onEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   * Updates the variable password  in state
   * @param  {event} e
   */
  onPass = (e) => {
    const hashedPassword = bcrypt.hashSync(
      e.target.value,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    this.setState({ password: hashedPassword });
  };

  /**
   * Updates the variable nome of the client in state
   * @param  {event} e
   */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };
  /**
   * Post user data changes to the API
   * @param state this.state State of the Component
   * @param integer this.state.id ID of the user logged in
   * */
  edit = (e) => {
    axios.put(API_URL_SADMIN + this.state.id, this.state).then(() => {
      this.openNotification("Os dados foram atualizados com sucesso!");
    });
  };

  /**
   * Component to edit Super Admin information */
  perfil() {
    return (
      <Row className="row justify-content-center " style={{ paddingTop: 50 }}>
        <Col></Col>
        <Col span={7}>
          <h5>Perfil</h5>

          <Form
            layout="vertical"
            onFinish={this.edit}
            fields={[
              {
                name: ["email"],
                value: this.state.email,
              },
              {
                name: ["name"],
                value: this.state.name,
              },
            ]}
          >
            <Form.Item label="Nome:" name="name">
              <Input
                type="text"
                name="name"
                placeholder="João Silva"
                onChange={this.onNome}
                value={this.state.name}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email:"
              rules={[
                {
                  required: "true",
                  pattern: new RegExp(
                    "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@tectank.pt$"
                  ),
                  message: "Email inválido",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="joaosilva@tectank.pt"
                name="email"
                value={this.state.email}
                onChange={this.onEmail}
              />
            </Form.Item>

            <Form.Item label="Password" name="password">
              <Input.Password onChange={this.onPass} />
            </Form.Item>

            <Form.Item style={{ textAlign: "right", right: -15 }}>
              <Button htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    );
  }

  /**
   * Opens a box with notification
   * @param {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };

  render() {
    return (
      <div>
        {this.state.login === "" ? <Navigate to="/" /> : ""}
        <Layout>
          <Menu
            className="upbar"
            mode="horizontal"
            triggerSubMenuAction={"click"}
            defaultSelectedKeys={["m2"]}
          >
            <Menu.Item
              key="m1"
              onClick={() => {}}
              icon={<Avatar shape="square" src={""} />}
            ></Menu.Item>
            <Menu.Item
              key="m2"
              onClick={() => {
                this.alt("dash");
              }}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="m3"
              onClick={() => {
                this.alt("propostas");
              }}
            >
              Propostas
            </Menu.Item>
            <Menu.Item
              key="m4"
              onClick={() => {
                this.alt("clientes");
              }}
            >
              Clientes
            </Menu.Item>
            <Menu.Item
              key="m7"
              onClick={() => {
                this.alt("estatisticas");
              }}
            >
              Estatísticas
            </Menu.Item>
            <Menu.Item
              key="m8"
              onClick={() => {
                this.alt("Ajuda");
              }}
            >
              Ajuda
            </Menu.Item>

            <SubMenu
              key="m9"
              icon={
                <SettingTwoTone
                  style={{ fontSize: "22px" }}
                  twoToneColor="#F94D5A"
                />
              }
            >
              <SubMenu key="m10" className="menu_item" title="Administradores">
                <Menu.Item
                  key="setting:1"
                  onClick={() => {
                    this.alt("add_admin");
                  }}
                >
                  Criar
                </Menu.Item>
                <Menu.Item
                  key="setting:2"
                  onClick={() => {
                    this.alt("list_admin");
                  }}
                >
                  Consultar
                </Menu.Item>
              </SubMenu>
              <SubMenu key="m11" className="menu_item" title="Utilizadores">
                <Menu.Item
                  key="setting:3"
                  onClick={() => {
                    this.alt("add_user");
                  }}
                >
                  Criar
                </Menu.Item>
                <Menu.Item
                  key="setting:4"
                  onClick={() => {
                    this.alt("list_user");
                  }}
                >
                  Consultar
                </Menu.Item>
              </SubMenu>
              <SubMenu key="m12" className="menu_item" title="Tarifários">
                <Menu.Item
                  key="setting:5"
                  onClick={() => {
                    this.alt("add_tar");
                  }}
                >
                  Criar
                </Menu.Item>
                <Menu.Item
                  key="setting:6"
                  onClick={() => {
                    this.alt("list_tar");
                  }}
                >
                  Consultar
                </Menu.Item>
              </SubMenu>
            </SubMenu>

            <SubMenu
              key="m14"
              title={this.state.name}
              style={{ marginLeft: "auto" }}
            >
              <Menu.Item
                key="subsub1"
                className="menu_item"
                icon={<UserOutlined style={{ color: "#F94D5A" }} />}
                onClick={() => {
                  this.alt("perfil");
                }}
              >
                Perfil
              </Menu.Item>
              <Menu.Item
                key="subsub2"
                className="menu_item"
                icon={<LogoutOutlined style={{ color: "#F94D5A" }} />}
                onClick={this.logout}
              >
                {this.state.logout === true ? <Navigate to="/" /> : "Logout"}
              </Menu.Item>
            </SubMenu>
          </Menu>
          <Layout className="lay">
            <Content>{this.dashboard()}</Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

/**
 * Maps variabels to redux store*/
function mapStateToProps(state) {
  return {
    id: state.id,
    name: state.name,
    email: state.email,
    telemovel: state.telemovel,
    img: state.img,
    password: state.password,
  };
}

/**
 * Maps functions to redux store*/
function mapDispatchToProps(dispatch) {
  return {
    loginReset: () => dispatch(loginReset()),
    userReset: () => dispatch(userReset()),
    updateData: (data) => dispatch(updateData(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdmin);
