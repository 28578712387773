import { USER_LOGIN,USER_RESET } from "../actions/actionTypes";


/**
* Initial State of variables from the Redux store regarding the user
* @param integer id ID of the user
* @param string name Name of the user
* @param string email Email of the User
* @param string img Image of the User
*/
const initialState = {
    id: "",
    name: "",
    email:"",
    last_updated:"",
    img:"",



};


/**
* Exporting state to the Redux store when there there is an action trigger.
* @param integer id ID of the user
* @param string name Name of the user
* @param string email Email of the User
* @param string img Image of the User
*/
  export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            const newState = { ...state,
              id:action.id,
              name: action.name,
              email: action.email,


              last_updated:action.last_updated,
              img:action.img,

            };
            return newState;
          case USER_RESET:
            return initialState;
          default:
            return state;
        }
  };
