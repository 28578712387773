import React, { Component } from "react";
import "../css/base.css";
import axios from "axios";
import { Button, Form, Input, Row, Col, notification } from "antd";
import bcrypt from "bcryptjs";
import { API_URL_ADMIN, API_URL_EMAIL_ADMIN } from "../constants";

class NewAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      email: "",
      password: "",
      telemovel: "",
    };
  }

  /**
   * Get the props data
   */
  componentDidMount() {
    if (this.props.data) {
      const { id, name, email, password, telemovel } = this.props.data;
      if (telemovel != 0) {
        this.setState({ id, name, email, password, telemovel });
      } else {
        this.setState({ id, name, email, password });
      }
    } else {
      const pass = bcrypt.hashSync("password", "$2a$10$CwTycUXWue0Thq9StjUM0u");
      this.setState({ password: pass });
    }
  }

  /**
   * Updates the variable name of the client in state
   *   @param  {event} e
   * */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };

  /**
   * Updates the variable email of the client in state
   * @param  {event} e
   */
  onEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   *  Updates the variable telemovel of the client in state
   * @param  {event} e
   *
   */
  onTel = (e) => {
    this.setState({ telemovel: e.target.value });
  };

  /**
   * Reset the password
   * @param  {event} e
   */
  onPass = () => {
    const pass = bcrypt.hashSync("password", "$2a$10$CwTycUXWue0Thq9StjUM0u");
    this.setState({ password: pass });
  };

  /**
   * Edit admin detais and send an API PUT Request
   * @param {*} e
   */
  edit = (e) => {
    axios.put(API_URL_ADMIN + this.state.id, this.state).then((res) => {
      this.openNotification("O administrador foi editado com sucesso.");
      this.props.resetState();
      this.props.toggle();
    });
  };

  /**
   * Create admin detais and send an API POST Request
   * @param {*} e
   */
  create = (e) => {
    axios.post(API_URL_ADMIN, this.state).then((res) => {
      axios.post(API_URL_EMAIL_ADMIN, { email: this.state.email });
      this.openNotification("O administrador foi criado com sucesso.");
      this.props.resetState();
      this.props.menu("inicio");
    });
  };

  /**
   * Notification components
   * Appears in a box
   * @param  {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      duration: 1.5,
      description: texto,
    });
  };

  render() {
    return (
      <div>
        <Row className="row justify-content-center">
          <Col></Col>
          <Col span={10}>
            <div style={{ paddingTop: 25 }}>
              <h4>Administrador</h4>
            </div>
            <Form
              fields={[
                {
                  name: ["email"],
                  value: this.state.email,
                },
                {
                  name: ["telemovel"],
                  value: this.state.telemovel,
                },
              ]}
              style={{ paddingTop: 25 }}
              layout="vertical"
              onFinish={this.props.data ? this.edit : this.create}
            >
              <Form.Item label="Nome:" required>
                <Input
                  type="text"
                  required
                  placeholder="João Silva"
                  value={this.state.name}
                  onChange={this.onNome}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    required: "true",
                    pattern: new RegExp(
                      "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@tectank.pt$"
                    ),
                    message: "Formato de e-mail inválido.",
                  },
                ]}
              >
                <Input
                  required
                  type="email"
                  placeholder="joaosilva@tectank.pt"
                  value={this.state.email}
                  name="email"
                  onChange={this.onEmail}
                />
              </Form.Item>
              <Form.Item
                name="telemovel"
                label="Telemóvel:"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      "(9[1236][0-9]) ?([0-9]{3}) ?([0-9]{3})"
                    ),
                    message: "Verifique o numero de Telemóvel.",
                  },
                  {
                    pattern: new RegExp(/\d+/g),
                    message: "Insira apenas números",
                  },
                ]}
              >
                <Input
                  name="telemovel"
                  type="tel"
                  minLength={9}
                  maxLength={9}
                  required
                  placeholder="911111111"
                  value={this.state.telemovel}
                  onChange={this.onTel}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Button htmlType="submit">Gravar</Button>
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Button onClick={this.onPass}>Repor Password</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </div>
    );
  }
}

export default NewAdmin;
