import React, { Component } from "react";
import { Modal } from "antd";
import '../css/base.css';
import { EyeFilled } from '@ant-design/icons';
import EditProposta from "./editProposta";
import NewAdmin from "./NewAdmin";
import NewTarifario from './newTarifario'
import NewUser from "./NewUser";
import NewCliente from "./newCliente";



class NewDataModal extends Component {
    state = {
        modal: false,
        info: "",
        data: "",
    };

    /**
     * Get data from props
     */
    componentDidMount() {
        let dados = this.props.data;
        if (dados) {
            this.setState({ data: dados, info: this.props.info });
        }

    }

    /**
     * Managing the open/closed state of modal
     */
    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    /**
     * Managing which form appears in modal based on info passed and the data.
     * @param {*} e 
     * @returns Component 
     */
    dados = e => {

        let info = this.props.info;
        let dados = this.props.data;


        switch (info) {

            case "propostas":
                return (<EditProposta resetState={this.props.resetState} notify={this.props.notify} toggle={this.toggle} data={dados} />);
            case "tar":
                return (<NewTarifario resetState={this.props.resetState} notify={this.props.notify} toggle={this.toggle} data={dados} />);

            case "clientes":
                return (<NewCliente resetState={this.props.resetState} notify={this.props.notify} toggle={this.toggle} data={dados} />);

            case "users":
                return (<NewUser resetState={this.props.resetState} notify={this.props.notify}  toggle={this.toggle} data={dados} />);
            case "admin":
                return (<NewAdmin resetState={this.props.resetState} notify={this.props.notify} toggle={this.toggle} data={dados} />);
            default: return ("no edit!");

        }






    }

    /**
    * Click on Cancel button from modal to close
    */
    handleCancel = () => {
        this.setState({
            modal: false
        });
    }

    render() {


        return (
            <>
                <EyeFilled style={{ fontSize: '22px', color: "#F94D5A" }} onClick={() => this.toggle()} />
                <Modal destroyOnClose={true} mask onCancel={this.handleCancel} maskClosable footer={null} style={{ top: 20 }} visible={this.state.modal}  >
                    <p>
                        {this.dados()}
                    </p>

                </Modal>
            </>
        );
    }
}


export default NewDataModal;
