import React, { Component, Fragment } from "react";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import { Button, notification } from "antd";

import { DeleteFilled } from '@ant-design/icons';

class LeaveModal extends Component {
  state = {
    modal: false
  };

  /**
   * Manages if modal is open
   */
  toggle = () => {
    this.setState(previous => ({
      modal: !previous.modal
    }));
  };

  /**
   * Notification components
   * Appears in a box
   * @param  {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: 'Notificação',
      duration: 1.5,
      description:
        texto,

    });
  };

  render() {
    return (
      <Fragment>

        <Button  type="button" onClick={() => this.toggle()}>Voltar</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>

          <ModalBody>
            Deseja voltar ao menu anterior ?
          </ModalBody>

          <ModalFooter>
            <Button shape="round" type="button" onClick={() => this.toggle()}>Cancelar</Button>
            <Button shape="round" type="button" color="primary" onClick={() => this.props.menu('inicio')}>
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
export default LeaveModal;