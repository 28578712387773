import React,{Component} from 'react';
import './App.css';
import Login from './components/login'
import 'bootstrap/dist/css/bootstrap.min.css';
import history from './history';
import {  BrowserRouter,  Routes,  Route} from "react-router-dom";
import SuperAdmin from './components/superadmin'
import Admin from './components/admin'
import User from './components/user'



/**
* Principal Component  to render.
* Defining routes for SAP using React Router.
* @param history history History from the Browser
*/
class App extends Component {

  render() {
 return (
    <div>
    <BrowserRouter history={history}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Super_Admin" element={<SuperAdmin />} />
        <Route path="/Admin_User" element={<Admin />} />
        <Route path="/User" element={<User />} />
      </Routes>
    </BrowserRouter>
  </div>
  );

  }

}

export default App;
