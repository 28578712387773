import React, { Component } from "react";
import "../css/base.css";
import axios from "axios";
import { Button, Form, Input, Row, Col, notification } from "antd";
import PersistedStore from "../store";
import { API_URL_TARIFARIO, API_URL_CLIENTES } from "../constants";

class NewCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      email: "",
      telemovel: "",
      morada: "",
      obs: "",
      nif: "",
      tarifarios: [],
      clientes: [],
    };
  }

  /**
   * Get data from API
   */
  componentDidMount() {
    axios.get(API_URL_TARIFARIO).then((res) => {
      var data = res.data;
      this.setState({ tarifarios: data });
    });

    axios.get(API_URL_CLIENTES).then((res) => {
      var data = res.data;
      this.setState({ clientes: data });
    });

    if (this.props.data) {
      const {
        name,
        email,
        telemovel,
        morada,
        andar,
        cp,
        cidade,
        nif,
        id,
        obs,
      } = this.props.data;
      this.setState({
        name,
        email,
        telemovel,
        morada,
        andar,
        cp,
        cidade,
        nif,
        id,
        obs,
      });
    }

    this.data();
  }

  /**
   * Get data from the Redux store */
  data() {
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().userState;

    this.setState({ responsavel: val.email });
  }

  /**
   * Updates the variable name of the client in state
   *   @param  {event} e
   * */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };

  /**
   * Updates the variable email of the client in state
   * @param  {event} e
   */
  onEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   *  Updates the variable telemovel of the client in state
   * @param  {event} e
   *
   */
  onTel = (e) => {
    this.setState({ telemovel: e.target.value });
  };

  /**
   * Updates the variable morada of the client in state
   * @param  {event} e
   */
  onMorada = (e) => {
    this.setState({ morada: e.target.value });
  };

  /**
   * Updates the variable nif of the client in state
   * @param  {event} e
   */
  onNIF = (e) => {
    this.setState({ nif: e.target.value });
  };

  /**
   * Edit client detais and send an API PUT Request
   * @param {*} e
   */
  edit = (e) => {
    const { clientes, tarifarios, ...novoNome } = this.state;
    axios
      .put(API_URL_CLIENTES + this.state.id, novoNome)
      .then((res) => {
        this.props.notify("Cliente editado com Sucesso.");
        this.props.resetState();
        this.props.toggle();
      })
      .catch((err) => {
        if (err.response.data.nif == "clientes with this nif already exists.") {
          this.props.notify("Já existe um cliente com o mesmo NIF!");
        }
      });
  };
  create = (e) => {
    const { clientes, tarifarios, ...novoNome } = this.state;
    axios
      .post(API_URL_CLIENTES, novoNome)
      .then(() => {
        this.props.notify("Cliente criado com sucesso.");

        this.props.resetState();
        this.props.menu("inicio");
      })
      .catch((err) => {
        if (err.response.data.nif == "clientes with this nif already exists.") {
          this.props.notify("Já existe um cliente com o mesmo NIF!");
        }
      });
  };

  render() {
    return (
      <div>
        <Row
          className="justify-content-center"
          style={{ paddingBottom: 25, paddingTop: 25 }}
        >
          <h4>Cliente</h4>
        </Row>

        <div>
          <Row className="justify-content-center">
            <Col></Col>
            <Col span={15}>
              <Form
                layout="vertical"
                onFinish={this.props.data ? this.edit : this.create}
                fields={[
                  {
                    name: ["nif"],
                    value: this.state.nif,
                  },
                  {
                    name: ["email"],
                    value: this.state.email,
                  },

                  {
                    name: ["telemovel"],
                    value: this.state.telemovel,
                  },
                  {
                    name: ["fidelizacao"],
                    value: this.state.fidelizacao,
                  },
                ]}
              >
                <Row gutter={[30, 30]}>
                  <Col span={12}>
                    <Form.Item required label="Nome:">
                      <Input
                        required
                        type="text"
                        placeholder="João Silva"
                        value={this.state.name}
                        onChange={this.onNome}
                      />
                    </Form.Item>

                    <Form.Item
                      name="nif"
                      label="NIF:"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(
                            "^[1235][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$"
                          ),
                          message: "Verifique o NIF.",
                        },
                        {
                          pattern: new RegExp(/\d+/g),
                          message: "Insira apenas números",
                        },
                      ]}
                    >
                      <Input
                        name="nif"
                        type="text"
                        minLength={9}
                        maxLength={9}
                        placeholder="123456789"
                        value={this.state.nif}
                        onChange={this.onNIF}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="email" label="Email:" required>
                      <Input
                        required
                        name="email"
                        placeholder="joaosilva@exemplo.pt"
                        type="email"
                        value={this.state.email}
                        onChange={this.onEmail}
                      />
                    </Form.Item>
                    <Form.Item
                      name="telemovel"
                      label="Telemóvel:"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(
                            "(9[1236][0-9]) ?([0-9]{3}) ?([0-9]{3})"
                          ),
                          message: "Verifique o numero de Telemóvel.",
                        },
                        {
                          pattern: new RegExp(/\d+/g),
                          message: "Insira apenas números",
                        },
                      ]}
                    >
                      <Input
                        name="telemovel"
                        type="tel"
                        minLength={9}
                        maxLength={9}
                        required
                        placeholder="911111111"
                        value={this.state.telemovel}
                        onChange={this.onTel}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item for="morada" label="Morada:" /*required*/>
                  <Input
                    type="text"
                    value={this.state.morada}
                    /*required*/ onChange={this.onMorada}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button htmlType="submit">Gravar</Button>
                </Form.Item>
              </Form>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default NewCliente;
