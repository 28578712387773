import React, { Component } from 'react';
import '../css/base.css';
import axios from "axios";
import { Button, Form, Input, Row, Col, Select, notification ,InputNumber } from 'antd';
import { API_URL_TARIFARIO } from '../constants';

const { Option } = Select
const { TextArea } = Input;


class NewTarifario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            name: "",
            quantidade: 0,
            dados_moveis: "",
            valor: 0,
            obs: "",
            produtos: [],

        };
    }

    /**
        * Get data from previous component
        */
    componentDidMount() {

        if (this.props.data) {
            const { id, name, quantidade, dados_moveis, valor, obs } = this.props.data;
            this.setState({ id, name, quantidade, dados_moveis, valor, obs });
        }
    }

    /**
  * Opens a notification bar with text
  * @param {string} texto 
  */
    openNotification = (texto) => {
        notification.open({
            message: 'Notificação',
            description: texto,

        });
    };

    /**
 * Updates the variable name of the client in state
 *   @param  {event} e
 * */
    onNome = (e) => {
        this.setState({ name: e.target.value })
    }

    /**
     * Updates the variable name of the tarifario in state
     *   @param  {event} e
     * */
    onTarifario = (e) => {

        this.setState({ name: e.target.value })
    }

    /**
     * Updates the variable dados_moveis in state
     *   @param  {event} e
     * */
    onDadosMoveis = (e) => {
        this.setState({ dados_moveis: e.target.value })
    }

    /**
     * Updates the variable quantidade in state
     *   @param  {event} e
     * */
    onQuantidade = (e) => {
        this.setState({ quantidade: e })
    }

    /**
    * Updates the variable obs in state
    *   @param  {event} e
    * */
    onObs = (e) => {
        this.setState({ obs: e.target.value })
    }

    /**
    * Updates the variable valor in state
    *   @param  {event} e
    * */
    onValor = (e) => {
        this.setState({ valor: e })
    }

    /**
  * Create tarifario and send an API POST Request
  * @param {*} e 
  */
    create = e => {

        axios.post(API_URL_TARIFARIO, this.state).then(res => {
            this.openNotification('O tarifário foi criado com sucesso.')
            this.props.menu('inicio')

            this.props.resetState()
        })
    }

    /**
  * Edit tarifario  and send an API PUT Request
  * @param {*} e 
  */
    edit = e => {
       
        axios.put(API_URL_TARIFARIO + this.state.id, this.state).then(res => {
            this.openNotification('Tarifário editado com sucesso.')
            this.props.resetState()
            this.props.toggle() 
            this.resetState()


        })

    }

    resetState = e => {
        this.setState({
            id: "",
            name: "",
            quantidade: 0,
            dados_moveis: "",
            valor: 0,
            obs: "",
        })

    }



    render() {


        return (
            <div>

                <div>
                    <Row className='row justify-content-center'>
                        <Col ></Col>
                        <Col span={15}>

                            <div >
                                <h4>Novo Tarifário</h4>
                            </div>
                            <Form preserve={false} layout="vertical" onFinish={this.props.data ? this.edit : this.create}  >


                                <Row gutter={[30, 30]} style={{ paddingTop: 25 }} className='justify-content-center '>
                                    <Col span={12} >

                                        <Form.Item label='Tarifário:' required >
                                            <Input type="text" required value={this.state.name} onChange={this.onTarifario} />
                                        </Form.Item>
                                        <Form.Item label='Quantidade:' required>
                                            <InputNumber required value={this.state.quantidade} onChange={this.onQuantidade} />
                                        </Form.Item>
                                

                                    </Col>
                                    <Col span={12}>
                                        <Form.Item required label='Dados Móveis:'>
                                            <Input required type="text" value={this.state.dados_moveis} onChange={this.onDadosMoveis} />

                                        </Form.Item>
                                        <Form.Item required label='Valor:'>
                                            <InputNumber addonAfter="€" required type="text" value={this.state.valor} onChange={this.onValor} />

                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Form.Item for="obs" label='Caracteristicas:'>
                                    <TextArea rows={4} value={this.state.obs} onChange={this.onObs} />

                                </Form.Item>


                                <Form.Item style={{ textAlign: "right" }} >

                                    <Button danger htmlType="submit">Guardar</Button>


                                </Form.Item>


                            </Form>




                        </Col>

                        <Col ></Col>

                    </Row>



                </div >

            </div >


        )
    }

}




export default NewTarifario;
