import { LOGIN, LOGOUT } from "../actions/actionTypes";

/**
* Initial State of variables from the Redux store regarding the login
* @param string name Type of user that logged in
* @param string img Logo of the Company
*/
const initialState = {
    name: "",
    img:"",

};

/**
* Exporting state to the Redux store when there there is an action trigger.
* @param string name Type of user that logged in
* @param string img Logo of the Company
*/
export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            const newState = {
                ...state,
                name: action.name,
                img: action.img,
            };
            return newState;
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};
