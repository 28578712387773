import React, { Component, useEffect } from "react";
import "../css/base.css";
import axios from "axios";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Popover,
  notification,
  Space,
} from "antd";
import PersistedStore from "../store";
import {
  API_URL_TARIFARIO,
  API_URL_PROPOSTAS,
  API_URL_CLIENTES,
  API_URL_EMAIL,
  API_URL_PDF,
  API_URL_NOTIFICACAO,
  API_URL_PLANOS,
  API_URL_ADMIN,
} from "../constants";
import { PlusCircleFilled, CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Item from "antd/lib/list/Item";
const { TextArea } = Input;
const { uuid } = require("uuidv4");

const { Option } = Select;
class Proposta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      id: 0,
      name: "",
      email: "",
      telemovel: null,
      morada: "",
      andar: "",
      cp: "",
      cidade: "",
      obs: "",
      nif: "",
      tarifarios: [],
      clientes: [],
      searchValue: "",
      visible: false,
      valor: "",
      tarifario: [],
      client_id: 0,
      responsavel: "",
      status: "EM APROVACAO",
      fidelizacao: "",
      servico: "",
      valor2: "",
      planos: [
        {
          id: "0",
          tarifario: "",
          fidelizacao: "",
          valor: "",
          valor2: true,
          servico: "",
        },
      ],
      id_tarifario: "0",
      tarifariosFinal: [],
      tamanhoTar: 0,
    };
  }

  componentDidMount() {
    axios.get(API_URL_TARIFARIO).then((res) => {
      var data = res.data;
      this.setState({ tarifarios: data });
    });

    axios.get(API_URL_CLIENTES).then((res) => {
      var data = res.data;
      this.setState({
        clientes: data,
      });
      if (this.state.id > 0) {
        let d = data.filter((p) => this.state.client_id === p.id).pop();
        this.setState({
          name: d.name,
          email: d.email,
          telemovel: d.telemovel,
          morada: d.morada,
          andar: d.andar,
          cp: d.cp,
          cidade: d.cidade,
          nif: d.nif,
          clientes: data,
        });
      }
    });

    if (this.props.data) {
      const {
        id,
        obs,
        nif,
        desconto,
        valor,
        tarifario,
        client_id,
        responsavel,
        status,
      } = this.props.data;
      this.setState({
        id,
        obs,
        nif,
        desconto,
        valor,
        tarifario,
        client_id,
        responsavel,
        status,
        tarifario,
      });
      this.onLoadCliente();
    }

    this.data();
  }
  /** Load of a client, and updates the values */
  onLoadCliente() {
    const clientes = this.state.clientes;
    if (clientes.length > 0) {
      let d = clientes.filter((p) => this.state.client_id === p.id).pop();
      this.setState({
        name: d.name,
        email: d.email,
        telemovel: d.telemovel,
        morada: d.morada,
        andar: d.andar,
        cp: d.cp,
        cidade: d.cidade,
        nif: d.nif,
      });
    }
  }

  /** Get login data from Redux store*/
  data() {
    const store = PersistedStore.getDefaultStore().store;
    const vale = store.getState().loginState;
    this.setState({ login: vale.name });
  }

  /**
   * Updates the variable tarifario in state contain the selected tarifarios and total value accordingly
   *  */
  onTarifario = (e) => {
    let p = this.state.tarifarios;
    let servico = toString(e);
    let preco = 0;
    let name = "";
    // let i = 0
    // let j = 0
    // while (servico.length > i) {
    //     for (j = 0; p.length > j; j++) {
    //         if (p[j].id === parseInt(servico[i])) {
    //             preco = preco + p[j].valor
    //         }
    //     }
    //     i++
    // }
    this.state.tarifarios.forEach((element) => {
      if (element.id == e) {
        preco = element.valor;
        name = element.name;
      }
    });
    this.setState({
      tarifario: JSON.stringify(e),
      servico_id: servico,
      valor: preco,
      valor2: preco,
    });
    this.state.tarifario = name;
    this.state.tamanhoTar = this.state.tarifario.length;
    // Caso o valor seja 0, limpamos a variável se modo a obrigar o utilizador a colocar um valor, evitando que a fatura vá com 0 euros
    if (preco == 0) {
      this.setState({ valor: "" });
    }
  };

  /**
   * Get data from the loaded client
   * @param {*} e
   */
  onCliente = (e) => {
    let d = this.state.clientes.filter((t) => t.id === parseInt(e)).pop();
    this.setState({
      client_id: d.id,
      name: d.name,
      email: d.email,
      telemovel: d.telemovel,
      morada: d.morada,
      nif: d.nif,
    });
  };

  /**
   * Updates the variable name of the client in state
   *
   * */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };

  onTar = (e) => {
    let nome = "";
    let preco = 0;
    this.state.tarifarios.forEach((element) => {
      if (element.id == e) {
        nome = element.name;
        preco = element.valor;
      }
    });

    this.setState({ tarifario: { value: e, label: nome } });
    this.setState({ valor: preco, valor2: preco });
    this.state.tamanhoTar = this.state.tarifario.length;
    // Caso o valor seja 0, limpamos a variável se modo a obrigar o utilizador a colocar um valor, evitando que a fatura vá com 0 euros
    if (preco == 0) {
      this.setState({ valor: "" });
    }
    this.forceUpdate();
  };
  /**
   * Updates the variable email of the client in state
   * @param  {event} e
   */
  onEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   *  Updates the variable telemovel of the client in state
   * @param  {event} e
   */
  onTel = (e) => {
    this.setState({ telemovel: e.target.value });
  };

  /**
   * Updates the variable morada of the client in state
   * @param  {event} e
   */
  onMorada = (e) => {
    this.setState({ morada: e.target.value });
  };

  /**
   * Updates the variable nif of the client in state
   * @param  {event} e
   */
  onNIF = (e) => {
    this.setState({ nif: e.target.value });
  };

  /**
   * Updates the variable obs of the client in state
   * @param  {event} e
   */
  onObs = (e) => {
    this.setState({ obs: e.target.value });
  };

  /**
   * Updates the variable fidelizacao in state
   *  */
  onFidelizacao = (e) => {
    this.setState({ fidelizacao: e });
  };

  /**
   * Updates the variable servico of the client in state
   *   @param  {event} e
   * */
  onValor = (e) => {
    this.setState({ valor: e.target.value });
  };

  /**
   * Updates the variable servico of the client in state
   *   @param  {event} e
   * */
  onServico = (e) => {
    this.setState({ servico: e.target.value });
  };

  /**
   * Updates the variable responsavel of the client in state
   * @param  {event} e
   */
  onResponsavel = (e) => {
    this.setState({ responsavel: e.target.value });
  };

  /**
   * Resets state
   * @param {} e
   */
  initialValues = (e) => {
    this.setState({
      id: 0,
      name: "",
      email: "",
      telemovel: "",
      morada: "",
      andar: "",
      cp: "",
      cidade: "",
      obs: "",
      nif: "",
      valor: "",
      client_id: 0,
      responsavel: "",
      tarifario: [],
      fidelizacao: "",
      servico: "",
      status: "EM APROVACAO",
      valor2: "",
      planos: [
        {
          id: "0",
          tarifario: [],
          fidelizacao: "",
          valor: "",
          valor2: true,
          servico: "",
        },
      ],
      id_tarifario: "0",
      tamanhoTar: 0,
    });
    const arrayLinhaVazia = [
      {
        id: "0",
        tarifario: [],
        fidelizacao: "",
        valor: "",
        valor2: true,
        servico: "",
      },
    ];
    this.state.planos.length = 0;
    this.state.planos = arrayLinhaVazia;
    this.forceUpdate();
  };

  /**
   * Create a new Proposta and send to the API and send an email top the client if is an admin
   * @param {*} e
   */
  create = async (e) => {
    //Verificar se todos os campos estão preenchidos
    if (
      (this.state.tarifario == [] || this.state.fidelizacao == "") &&
      this.state.valor != "" &&
      this.state.servico != ""
    ) {
      alert("Preencha todos os campos obrigatórios!");
    } else {
      const { clientes, tarifarios, tarifariosFinal, ...novoNome } = this.state;
      if (this.state.login === "User") {
        if (this.state.client_id == 0) {
          axios
            .post(API_URL_CLIENTES, novoNome)
            .then((res) => {
              let data = res.data;
              let client_id = data.id;

              this.openNotification("O cliente foi criado com sucesso");

              //Id do plano
              const uuid4 = uuidv4();
              const id_plano = uuid4;

              //Inserir planos proposta
              if (1 >= this.state.planos.length <= 4) {
                this.state.planos.forEach((element) => {
                  if (element.id != "0") {
                    const plano = {
                      id_plano: id_plano,
                      tarifario: element.tarifario,
                      fidelizacao: element.fidelizacao,
                      servico: element.servico,
                      valor: element.valor,
                      valor2: element.valor2,
                    };
                    axios.post(API_URL_PLANOS, plano).then((res) => {});
                  }
                });
              }
              if (
                this.state.tarifario != [] &&
                this.state.fidelizacao != "" &&
                this.state.valor != "" &&
                this.state.servico != ""
              ) {
                //Valor2
                let editarValor = false;
                this.state.tarifarios.forEach((element) => {
                  if (element.id == this.state.tarifario.value) {
                    if (element.valor != 0) {
                      editarValor = true;
                    }
                  }
                });
                const plano = {
                  id_plano: id_plano,
                  tarifario: this.state.tarifario.value,
                  fidelizacao: this.state.fidelizacao,
                  servico: this.state.servico,
                  valor: this.state.valor,
                  valor2: editarValor,
                };
                axios.post(API_URL_PLANOS, plano).then((res) => {});
              }

              let dataa = new FormData();
              dataa.append("obs", this.state.obs);
              dataa.append("responsavel", this.state.responsavel);
              dataa.append("id_plano", id_plano);
              dataa.append("status", this.state.status);
              dataa.append("client_id", client_id);

              axios.post(API_URL_PROPOSTAS, dataa).then((res) => {
                this.props.menu();
                this.openNotification("A proposta foi enviada para aprovação");
                axios.post(API_URL_NOTIFICACAO);
                this.initialValues();
              });
            })
            .catch((err) => {
              if (
                err.response.data.nif ==
                "clientes with this nif already exists."
              ) {
                this.openNotification("Já existe um cliente com o mesmo NIF!");
              }
            });
        } else {
          //Id do plano
          const uuid4 = uuidv4();
          const id_plano = uuid4;

          //Inserir planos proposta
          if (1 >= this.state.planos.length <= 4) {
            this.state.planos.forEach((element) => {
              if (element.id != "0") {
                const plano = {
                  id_plano: id_plano,
                  tarifario: element.tarifario,
                  fidelizacao: element.fidelizacao,
                  servico: element.servico,
                  valor: element.valor,
                  valor2: element.valor2,
                };
                axios.post(API_URL_PLANOS, plano).then((res) => {});
              }
            });
          }
          if (
            this.state.tarifario != [] &&
            this.state.fidelizacao != "" &&
            this.state.valor != "" &&
            this.state.servico != ""
          ) {
            //Valor2
            let editarValor = false;
            this.state.tarifarios.forEach((element) => {
              if (element.id == this.state.tarifario.value) {
                if (element.valor != 0) {
                  editarValor = true;
                }
              }
            });
            const plano = {
              id_plano: id_plano,
              tarifario: this.state.tarifario.value,
              fidelizacao: this.state.fidelizacao,
              servico: this.state.servico,
              valor: this.state.valor,
              valor2: editarValor,
            };
            axios.post(API_URL_PLANOS, plano).then((res) => {});
          }
          const idPlano = id_plano;

          let dataa = new FormData();
          dataa.append("obs", this.state.obs);
          dataa.append("responsavel", this.state.responsavel);
          dataa.append("id_plano", idPlano);
          dataa.append("status", this.state.status);
          dataa.append("client_id", this.state.client_id);

          axios.post(API_URL_PROPOSTAS, dataa).then((res) => {
            this.props.menu();
            this.openNotification("A proposta foi enviada para aprovação");
            this.initialValues();
            axios.post(API_URL_NOTIFICACAO);
          });
        }
      } else {
        const { clientes, tarifarios, tarifariosFinal, ...novoNome } =
          this.state;

        if (this.state.client_id == 0) {
          axios
            .post(API_URL_CLIENTES, novoNome)
            .then(async (res) => {
              let data = res.data;
              let client_id = data.id;

              //Id do plano
              const uuid4 = uuidv4();
              const id_plano = uuid4;

              //Inserir planos proposta
              if (1 >= this.state.planos.length <= 4) {
                for (let i = 0; i < this.state.planos.length; i++) {
                  const element = this.state.planos[i];
                  if (element.id != "0") {
                    const plano = {
                      id_plano: id_plano,
                      tarifario: element.tarifario,
                      fidelizacao: element.fidelizacao,
                      servico: element.servico,
                      valor: element.valor,
                      valor2: element.valor2,
                    };
                    await axios.post(API_URL_PLANOS, plano);
                  }
                }
              }
              if (
                this.state.tarifario != [] &&
                this.state.fidelizacao != "" &&
                this.state.valor != "" &&
                this.state.servico != ""
              ) {
                //Valor2
                let editarValor = false;
                this.state.tarifarios.forEach((element) => {
                  if (element.id == this.state.tarifario.value) {
                    if (element.valor != 0) {
                      editarValor = true;
                    }
                  }
                });
                const plano = {
                  id_plano: id_plano,
                  tarifario: this.state.tarifario.value,
                  fidelizacao: this.state.fidelizacao,
                  servico: this.state.servico,
                  valor: this.state.valor,
                  valor2: editarValor,
                };
                await axios.post(API_URL_PLANOS, plano);
              }

              let dataa = new FormData();
              dataa.append("obs", this.state.obs);
              dataa.append("responsavel", this.state.responsavel);
              dataa.append("id_plano", id_plano);
              dataa.append("status", "APROVADO");
              dataa.append("client_id", client_id);

              axios.post(API_URL_PROPOSTAS, dataa).then((res) => {
                this.openNotification(
                  "A proposta e cliente foram criados com sucesso"
                );
                this.initialValues();
                let d = res.data;

                let valor = 0;
                const store = PersistedStore.getDefaultStore().store;
                const val = store.getState().userState;

                let propostaArray = [];
                let propostaHtml = "";

                axios.get(API_URL_PLANOS).then(async (res) => {
                  res.data.forEach((element) => {
                    if (element.id_plano == id_plano) {
                      valor += element.valor;

                      this.state.tarifarios.forEach((element2) => {
                        if (element.tarifario == element2.id) {
                          let obs = element2.obs.replaceAll("\n", "");

                          const linhaProposta = {
                            servico: element.servico,
                            tarifarioNome: element2.name,
                            tarifarioObs: obs.replaceAll(".", ".\n"),
                            fidelizacao: element.fidelizacao,
                            valor: element.valor,
                          };

                          const obsHtml = obs.replaceAll(
                            ".",
                            ".%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20"
                          );

                          propostaHtml +=
                            "%20%20%20%20%20%20%20%20%20%20%20%20Serviço:%20" +
                            element.servico +
                            "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Tarifário:%20" +
                            element2.name +
                            "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                            obsHtml.replaceAll(" ", "%20") +
                            "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Permanência:%20" +
                            element.fidelizacao +
                            "%20meses%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                            "Valor%20mensal:%20" +
                            element.valor +
                            "€%20%2B%20iva" +
                            "%0D%0A%0D%0A";

                          propostaArray.push(linhaProposta);
                        }
                      });
                    }
                  });
                  let telemovel = "";
                  if (store.getState().loginState.name == "Super_Admin") {
                    telemovel = "";
                  } else if (store.getState().loginState.name == "Admin_User") {
                    await axios.get(API_URL_ADMIN).then((res) => {
                      res.data.forEach((element) => {
                        if (element.id == val.id) {
                          telemovel = element.telemovel;
                        }
                      });
                    });
                  }
                  axios
                    .post(API_URL_EMAIL, {
                      proposta: d.id,
                      valor: valor.toFixed(2),
                      utilizador: val.name,
                      telemovel: telemovel,
                      linhasProposta: propostaArray,
                      html: propostaHtml,
                    })
                    .then((res) => {
                      this.initialValues();
                      this.openNotification("Proposta enviada para o cliente");

                      // let blob = new Blob([res.data], { type: 'application/pdf' }),
                      //   url = window.URL.createObjectURL(blob)

                      // window.open(url) // Mostly the same, I was just experimenting with dif
                    })
                    .catch((err) => {
                      this.openNotification(err);
                    });
                });
              });
            })
            .catch((err) => {
              if (
                err.response.data.nif ==
                "clientes with this nif already exists."
              ) {
                this.openNotification("Já existe um cliente com o mesmo NIF!");
              }
            });
        } else {
          //Id do plano
          const uuid4 = uuidv4();
          const id_plano = uuid4;

          //Inserir planos proposta
          if (1 >= this.state.planos.length <= 4) {
            for (let i = 0; i < this.state.planos.length; i++) {
              const element = this.state.planos[i];
              if (element.id != "0") {
                const plano = {
                  id_plano: id_plano,
                  tarifario: element.tarifario,
                  fidelizacao: element.fidelizacao,
                  servico: element.servico,
                  valor: element.valor,
                  valor2: element.valor2,
                };
                await axios.post(API_URL_PLANOS, plano);
              }
            }
          }
          if (
            this.state.tarifario != [] &&
            this.state.fidelizacao != "" &&
            this.state.valor != "" &&
            this.state.servico != ""
          ) {
            //Valor2
            let editarValor = false;
            this.state.tarifarios.forEach((element) => {
              if (element.id == this.state.tarifario.value) {
                if (element.valor != 0) {
                  editarValor = true;
                }
              }
            });
            const plano = {
              id_plano: id_plano,
              tarifario: this.state.tarifario.value,
              fidelizacao: this.state.fidelizacao,
              servico: this.state.servico,
              valor: this.state.valor,
              valor2: editarValor,
            };
            await axios.post(API_URL_PLANOS, plano);
          }

          let dataa = new FormData();
          dataa.append("obs", this.state.obs);
          dataa.append("responsavel", this.state.responsavel);
          dataa.append("id_plano", id_plano);
          dataa.append("status", "APROVADO");
          dataa.append("client_id", this.state.client_id);

          axios.post(API_URL_PROPOSTAS, dataa).then((res) => {
            this.openNotification("A proposta foi criada com sucesso");
            this.initialValues();
            let d = res.data;
            let valor = 0;
            const store = PersistedStore.getDefaultStore().store;
            const val = store.getState().userState;

            let propostaArray = [];
            let propostaHtml = "";

            axios.get(API_URL_PLANOS).then(async (res) => {
              res.data.forEach((element) => {
                if (element.id_plano == id_plano) {
                  valor += element.valor;

                  this.state.tarifarios.forEach((element2) => {
                    if (element.tarifario == element2.id) {
                      let obs = element2.obs.replaceAll("\n", "");

                      const linhaProposta = {
                        servico: element.servico,
                        tarifarioNome: element2.name,
                        tarifarioObs: obs.replaceAll(".", ".\n"),
                        fidelizacao: element.fidelizacao,
                        valor: element.valor,
                      };

                      const obsHtml = obs.replaceAll(
                        ".",
                        ".%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20"
                      );

                      propostaHtml +=
                        "%20%20%20%20%20%20%20%20%20%20%20%20Serviço:%20" +
                        element.servico +
                        "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Tarifário:%20" +
                        element2.name +
                        "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                        obsHtml.replaceAll(" ", "%20") +
                        "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Permanência:%20" +
                        element.fidelizacao +
                        "%20meses%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                        "Valor%20mensal:%20" +
                        element.valor +
                        "€%20%2B%20iva" +
                        "%0D%0A%0D%0A";

                      propostaArray.push(linhaProposta);
                    }
                  });
                }
              });
              let telemovel = "";
              if (store.getState().loginState.name == "Super_Admin") {
                telemovel = "";
              } else if (store.getState().loginState.name == "Admin_User") {
                await axios.get(API_URL_ADMIN).then((res) => {
                  res.data.forEach((element) => {
                    if (element.id == val.id) {
                      telemovel = element.telemovel;
                    }
                  });
                });
              }
              axios
                .post(API_URL_EMAIL, {
                  proposta: d.id,
                  valor: valor.toFixed(2),
                  utilizador: val.name,
                  telemovel: telemovel,
                  linhasProposta: propostaArray,
                  html: propostaHtml,
                })
                .then((res) => {
                  this.props.menu();
                  this.initialValues();
                  this.openNotification("Proposta enviada para o cliente");

                  // let blob = new Blob([res.data], { type: 'application/pdf' }),
                  //   url = window.URL.createObjectURL(blob)

                  // window.open(url) // Mostly the same, I was just experimenting with dif
                })
                .catch((err) => {
                  this.openNotification(err);
                });
            });
          });
        }
      }
    }
  };

  /**
   * Send email to client with proposta
   * @param {*} e
   */
  sendClient = (e) => {
    let valor = 0;
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().userState;

    let propostaArray = [];
    let propostaHtml = "";

    axios.get(API_URL_PLANOS).then(async (res) => {
      res.data.forEach((element) => {
        if (element.id_plano == this.state.id_plano) {
          valor += element.valor;

          this.state.tarifarios.forEach((element2) => {
            if (element.tarifario == element2.id) {
              let obs = element2.obs.replaceAll("\n", "");

              const linhaProposta = {
                servico: element.servico,
                tarifarioNome: element2.name,
                tarifarioObs: obs.replaceAll(".", ".\n"),
                fidelizacao: element.fidelizacao,
                valor: element.valor,
              };

              const obsHtml = obs.replaceAll(
                ".",
                ".%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20"
              );

              propostaHtml +=
                "%20%20%20%20%20%20%20%20%20%20%20%20Serviço:%20" +
                element.servico +
                "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Tarifário:%20" +
                element2.name +
                "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                obsHtml.replaceAll(" ", "%20") +
                "%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20Permanência:%20" +
                element.fidelizacao +
                "%20meses%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20" +
                "Valor%20mensal:%20" +
                element.valor +
                "€%20%2B%20iva" +
                "%0D%0A%0D%0A";

              propostaArray.push(linhaProposta);
            }
          });
        }
      });
      let telemovel = "";
      if (store.getState().loginState.name == "Super_Admin") {
        telemovel = "";
      } else if (store.getState().loginState.name == "Admin_User") {
        await axios.get(API_URL_ADMIN).then((res) => {
          res.data.forEach((element) => {
            if (element.id == val.id) {
              telemovel = element.telemovel;
            }
          });
        });
      }
      axios
        .post(API_URL_EMAIL, {
          proposta: this.state.id,
          valor: valor.toFixed(2),
          utilizador: val.name,
          telemovel: telemovel,
          linhasProposta: propostaArray,
          html: propostaHtml,
        })
        .then((res) => {
          this.openNotification("Proposta enviada para o cliente");

          // let blob = new Blob([res.data], { type: 'application/pdf' }),
          //   url = window.URL.createObjectURL(blob)

          // window.open(url) // Mostly the same, I was just experimenting with dif
        })
        .catch((err) => {
          this.openNotification(err);
        });
    });
  };

  /**
   * Opens a notification bar with text
   * @param {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };

  /**
   * Get tarifario name
   * @param {string} e ID of tarifario
   * @returns name o tarifario
   */
  func = (e) => {
    let obj = this.state.tarifarios.filter((p) => p.id === parseInt(e));
    if (obj.length !== 0) {
      let o = obj.pop();
      if (o !== undefined) {
        if (o.name !== undefined) {
          return o.name;
        }
      }
    }
  };

  /**
   * Get id from tarifario
   * @param {string} e
   * @returns id
   */
  tari = (e) => {
    if (e !== 0) {
      if (this.state.tarifarios.length !== 0 && e.length !== 0) {
        let tarifario = [];
        tarifario = e.map(this.func);
        return tarifario;
      }
    }
  };

  //Adicionar linha do tarifário
  adicionarLinha = (e) => {
    this.forceUpdate();
    let condicaoArray = false;
    let condicaoArray2 = false;
    //Verificar se no array existe no mínimo um plano sem o id 0
    this.state.planos.forEach((element) => {
      if (element.id != "0") {
        condicaoArray = true;
      } else {
        condicaoArray2 = !condicaoArray2;
      }
    });
    if (
      (this.state.tarifario != [] &&
        this.state.tarifario.value != undefined &&
        this.state.fidelizacao != "" &&
        this.state.valor != "" &&
        this.state.servico != "") ||
      condicaoArray
    ) {
      //Quando a array apenas contém uma linha preenchida, linha da qual não tem o id 0, ou seja, as variáveis estão vazias
      if (
        condicaoArray &&
        (this.state.tarifario == [] ||
          this.state.tarifario.value == undefined) &&
        this.state.fidelizacao == "" &&
        this.state.valor == "" &&
        this.state.servico == "" &&
        !condicaoArray2
      ) {
        let camposVazios = false;

        //Verificar se a linha tem todos os campos preenchidos
        this.state.planos.forEach((element) => {
          if (
            element.tarifario == "" ||
            element.fidelizacao == "" ||
            element.valor == "" ||
            element.servico == ""
          ) {
            camposVazios = true;
          }
        });

        if (!camposVazios) {
          //Adicionar array com linha vazia
          const arrayLinhaVazia = {
            id: "0",
            tarifario: [],
            fidelizacao: "",
            valor: "",
            valor2: true,
            servico: "",
          };

          let editarValor = false;
          //Verificar se o tarifário já tem um preço pré-definido
          this.state.tarifarios.forEach((element) => {
            if (element.id == this.state.tarifario.value) {
              if (element.valor == 0) {
                editarValor = true;
              }
            }
          });

          let arrayFinal = [];
          //Ir buscar as linhas já existentes
          this.state.planos.forEach((element) => {
            const linha = {
              id: element.id,
              tarifario: element.tarifario + "",
              fidelizacao: element.fidelizacao,
              valor: element.valor,
              valor2: editarValor,
              servico: element.servico,
            };
            arrayFinal.push(linha);
          });

          arrayFinal.push(arrayLinhaVazia);
          this.state.planos.length = 0;
          this.state.planos = arrayFinal;
        } else {
          alert("Preencha todos os dados da linha!");
        }
      } else {
        if (
          condicaoArray &&
          (this.state.tarifario == [] ||
            this.state.tarifario.value == undefined ||
            this.state.fidelizacao == "" ||
            this.state.valor == "" ||
            this.state.servico == "")
        ) {
          alert("Preencha todos os dados da linha!");
        } else {
          //Adicionar array com linha vazia
          const arrayLinhaVazia = {
            id: "0",
            tarifario: [],
            fidelizacao: "",
            valor: "",
            valor2: true,
            servico: "",
          };

          //A variável tarifário aparecia ""1""", foi utilizado a função replace 2 vezes de modo a substituir dois dos " por 0
          let str = this.state.tarifario.value;
          // let newStr = str.replace('"', '');
          // let newStr2 = newStr.replace('"', '');

          let editarValor = false;
          //Verificar se o tarifário já tem um preço pré-definido
          this.state.tarifarios.forEach((element) => {
            if (element.id == this.state.tarifario.value) {
              if (element.valor == 0) {
                editarValor = true;
              }
            }
          });

          //Adicionar linha preenchida
          const uuid4 = uuidv4();
          const arrayLinhaNova = {
            id: uuid4,
            tarifario: this.state.tarifario.value + "",
            fidelizacao: this.state.fidelizacao,
            valor: this.state.valor,
            valor2: editarValor,
            servico: this.state.servico,
          };

          //Ir buscar as linhas já existentes
          let linhasExistentes = [];
          this.state.planos.forEach((element) => {
            if (element.id != 0 && element.id != uuid4) {
              const linha = {
                id: element.id,
                tarifario: element.tarifario,
                fidelizacao: element.fidelizacao,
                valor: element.valor,
                valor2: element.valor2,
                servico: element.servico,
              };
              linhasExistentes.push(linha);
            }
          });

          //Adicionar ao array: linhas existentes, linha nova, linha vazia
          if (linhasExistentes.length == 0) {
            this.state.planos.length = 0;
            let arrayFinal = [];
            arrayFinal.push(arrayLinhaNova, arrayLinhaVazia);
            this.state.planos = arrayFinal;
          } else {
            let arrayFinal = [];
            this.state.planos.forEach((element) => {
              if (element.id != 0 && element.id != uuid4) {
                const linha = {
                  id: element.id,
                  tarifario: element.tarifario,
                  fidelizacao: element.fidelizacao,
                  valor: element.valor,
                  valor2: element.valor2,
                  servico: element.servico,
                };
                arrayFinal.push(linha);
              }
            });
            arrayFinal.push(arrayLinhaNova, arrayLinhaVazia);
            this.state.planos.length = 0;
            this.state.planos = arrayFinal;
          }

          //Limpar variáveis
          this.state.id_tarifario = "0";
          this.state.tarifario = [];
          this.state.fidelizacao = "";
          this.state.valor = "";
          this.state.servico = "";

          this.forceUpdate();
        }
      }
    } else {
      alert("Preencha todos os dados da linha!");
    }
  };

  //Eliminar linha do tarifário
  eliminarLinha = (e) => {
    let novoArrayPlanos = [];
    this.state.planos.forEach((element) => {
      if (element.id != e) {
        const plano = {
          id: element.id,
          tarifario: element.tarifario,
          fidelizacao: element.fidelizacao,
          valor: element.valor,
          valor2: element.valor2,
          servico: element.servico,
        };
        novoArrayPlanos.push(plano);
      }
    });
    //Caso o id seja 0, significa que temos que colocar as variáveis vazias
    if (e == "0") {
      this.state.tarifario = [];
      this.state.fidelizacao = "";
      this.state.valor = "";
      this.state.valor2 = "0";
      this.state.servico = "";
    }
    //Caso o id da linha a ser eliminado seja
    //Escrever novo array de planos
    this.state.planos = novoArrayPlanos;
    this.forceUpdate();
  };

  //Editar linha do tarifário
  editarLinha = (id, tarifario, fidelizacao, valor, valor2, servico) => {
    //Atribuir o preco do tarifário à variável valor2
    // let preco = 0;
    // this.state.tarifarios.forEach(element => {
    //     if(element.id == parseInt(tarifario)){
    //         preco = element.valor;
    //     }

    // });
    // this.state.valor2 = preco;
    let arrayEditado = [];
    this.state.planos.forEach((element) => {
      if (element.id == id) {
        //Se o tarifário for atualizado, é necessário atualizar as variáveis valor e valor2
        let preco = 0;
        let editarPreco = false;
        this.state.tarifarios.forEach((element) => {
          if (element.id == tarifario) {
            if (element.valor == 0) {
              editarPreco = true;
              //Verificar se o tarifário foi alterado, caso seja o preco fica vazio
              this.state.planos.forEach((element) => {
                if (element.id == id) {
                  if (element.tarifario == tarifario) {
                    preco = valor;
                  } else {
                    preco = "";
                  }
                }
              });
            } else {
              preco = element.valor;
            }
          }
        });

        const plano = {
          id: id,
          tarifario: tarifario,
          fidelizacao: fidelizacao,
          valor: preco,
          valor2: editarPreco,
          servico: servico,
        };
        arrayEditado.push(plano);
      } else {
        const plano = {
          id: element.id,
          tarifario: element.tarifario,
          fidelizacao: element.fidelizacao,
          valor: element.valor,
          valor2: element.valor2,
          servico: element.servico,
        };
        arrayEditado.push(plano);
      }
    });
    this.state.planos.length = 0;
    this.state.planos = arrayEditado;
    this.forceUpdate();
  };

  atualizarPreco = (id) => {
    let preco = 0;
    this.state.tarifarios.forEach((element) => {
      if (element.id == parseInt(id)) {
        preco = element.valor;
      }
    });
    this.state.valor2 = preco;
  };

  onSearch = (event) => {
    const { value } = event.target;
    this.setState({ searchValue: value });
  };

  filterClientes = () => {
    const { clientes, searchValue } = this.state;
    if (searchValue) {
      return clientes.filter((cliente) =>
        cliente.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      return clientes;
    }
  };

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleSelect = () => {
    this.handleVisibleChange(false);
  };

  render() {
    const tarifario = this.state.tarifarios;
    const clientes = this.state.clientes;
    const { searchValue } = this.state;
    const filteredClientes = this.filterClientes();

    let tarifarios = [];
    this.state.tarifarios.forEach((element) => {
      const tar = {
        value: element.id,
        label: element.name,
      };
      tarifarios.push(tar);
    });
    this.state.tarifariosFinal = tarifarios;

    return (
      <div>
        <div>
          <Row className="row justify-content-center">
            <Col></Col>
            <Col span={15}>
              <Row className="list_nova_proposta">
                <div>
                  <h5>Nova Proposta</h5>
                  Crie uma nova proposta para um cliente
                </div>
              </Row>
              <Row className="list_nova_proposta" justify="end">
                <Popover
                  placement="bottom"
                  content={
                    <div>
                      <Autocomplete
                        style={{ width: "200px" }}
                        options={clientes}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        onInputChange={(event, value) => {
                          const selectedOption = clientes.find(
                            (option) => option.name === value
                          );
                          if (selectedOption) {
                            this.onCliente(selectedOption.id);
                          } else {
                            this.setState({
                              client_id: 0,
                              name: "",
                              email: "",
                              telemovel: null,
                              morada: "",
                              nif: "",
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Pesquisar Cliente"
                            placeholder="Pesquisar Cliente"
                            onChange={this.onSearch}
                            value={searchValue}
                          />
                        )}
                      />
                    </div>
                  }
                  trigger="click"
                >
                  <Space>
                    <PlusCircleFilled
                      style={{ fontSize: "30px", color: "#F94D5A" }}
                    />
                    <h5 style={{ paddingTop: 7 }}>Carregar Cliente</h5>
                  </Space>
                </Popover>
              </Row>
              <div className="list_nova_proposta">
                <Form
                  layout="vertical"
                  onFinish={this.create}
                  fields={[
                    {
                      name: ["nif"],
                      value: this.state.nif,
                    },
                    {
                      name: ["email"],
                      value: this.state.email,
                    },

                    {
                      name: ["telemovel"],
                      value: this.state.telemovel,
                    },
                    {
                      name: ["fidelizacao"],
                      value: this.state.fidelizacao,
                    },
                    {
                      name: ["servico"],
                      value: this.state.servico,
                    },
                  ]}
                >
                  <h4>Dados Pessoais</h4>
                  <Row gutter={[30, 30]}>
                    <Col span={8}>
                      <Form.Item label="Cliente:" required>
                        <Input
                          type="text"
                          placeholder="João Silva"
                          value={this.state.name}
                          required
                          onChange={this.onNome}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="nif"
                        label="NIF:"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(
                              "^[1235][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$"
                            ),
                            message: "Verifique o formato do NIF.",
                          },
                          {
                            pattern: new RegExp(/\d+/g),
                            message: "Insira apenas números",
                          },
                        ]}
                      >
                        <Input
                          name="nif"
                          minLength={9}
                          maxLength={9}
                          type="text"
                          placeholder="123456789"
                          value={this.state.nif}
                          required
                          onChange={this.onNIF}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="email" label="Email:" required>
                        <Input
                          required
                          name="email"
                          placeholder="joaosilva@exemplo.com"
                          type="email"
                          value={this.state.email}
                          onChange={this.onEmail}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[30, 30]}>
                    <Col span={16}>
                      <Form.Item for="morada" label="Morada:" /*required*/>
                        <Input
                          type="text"
                          value={this.state.morada}
                          onChange={this.onMorada} /*required*/
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="telemovel"
                        label="Telemóvel:"
                        rules={[
                          {
                            required: true,
                            pattern: new RegExp(
                              "(9[1236][0-9]) ?([0-9]{3}) ?([0-9]{3})"
                            ),
                            message: "Verifique o número.Ex: 912348594",
                          },
                          {
                            pattern: new RegExp(/\d+/g),
                            message: "Insira apenas números",
                          },
                        ]}
                      >
                        <Input
                          type="tel"
                          minLength={9}
                          maxLength={9}
                          placeholder="911111111"
                          name="telemovel"
                          required
                          value={this.state.telemovel}
                          onChange={this.onTel}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[30, 30]}>
                    <Col span={16}>
                      <Form.Item
                        for="responsavel"
                        label="Responsável:"
                        required
                      >
                        <Input
                          required
                          type="text"
                          value={this.state.responsavel}
                          onChange={this.onResponsavel}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <hr />
                  <h4>Plano de proposta</h4>

                  {this.state.planos.map((item) => (
                    <>
                      {item.id != 0 ? (
                        <Row gutter={[40, 40]}>
                          <Col span={this.state.planos.length > 1 ? 5 : 6}>
                            <Form.Item label="Tarifário:" required>
                              <Select
                                id="tarifario"
                                value={item.tarifario}
                                onChange={(e) => {
                                  this.editarLinha(
                                    item.id,
                                    e,
                                    item.fidelizacao,
                                    item.valor,
                                    item.valor2,
                                    item.servico
                                  );
                                }}
                              >
                                {!tarifario || tarifario.length <= 0 ? (
                                  <Option key="0">Sem dados!</Option>
                                ) : (
                                  tarifario.map((l) => (
                                    <Option key={l.id}>{l.name}</Option>
                                  ))
                                )}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={this.state.planos.length > 1 ? 5 : 6}>
                            <Form.Item label="Fidelização:" required>
                              <Select
                                id="fidelizacao"
                                onChange={(e) => {
                                  this.editarLinha(
                                    item.id,
                                    item.tarifario,
                                    e,
                                    item.valor,
                                    item.valor2,
                                    item.servico
                                  );
                                }}
                                defaultValue={item.fidelizacao}
                              >
                                <Option key="24">24 meses</Option>
                                <Option key="30">30 meses</Option>
                                <Option key="36">36 meses</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {item.tarifario.length != 0 && !item.valor2 ? (
                            <Col span={this.state.planos.length > 1 ? 5 : 6}>
                              <Form.Item label="Valor:" required>
                                <Input
                                  type="text"
                                  value={item.valor}
                                  required
                                />
                              </Form.Item>
                            </Col>
                          ) : (
                            <Col span={this.state.planos.length > 1 ? 5 : 6}>
                              <Form.Item label="Valor:" required>
                                <Input
                                  type="text"
                                  required
                                  placeholder="Insira um valor"
                                  value={item.valor}
                                  onChange={(e) => {
                                    this.editarLinha(
                                      item.id,
                                      item.tarifario,
                                      item.fidelizacao,
                                      e.target.value,
                                      item.valor2,
                                      item.servico
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          )}

                          <Col span={this.state.planos.length > 1 ? 5 : 6}>
                            <Form.Item label="Serviço:" required>
                              <Input
                                required
                                type="text"
                                value={item.servico}
                                onChange={(e) => {
                                  this.editarLinha(
                                    item.id,
                                    item.tarifario,
                                    item.fidelizacao,
                                    item.valor,
                                    item.valor2,
                                    e.target.value
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>

                          {this.state.planos.length > 1 && (
                            <Col span={3} style={{ textAlign: "center" }}>
                              <Form.Item label=" ">
                                <CloseOutlined
                                  style={{
                                    fontSize: "20px",
                                    color: "#F94D5A",
                                    cursor: "pointer",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    this.eliminarLinha(item.id);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      ) : (
                        <Row gutter={[40, 40]}>
                          <Col span={this.state.planos.length > 1 ? 5 : 6}>
                            <Form.Item label="Tarifário:" required>
                              <Select
                                id="tarifario"
                                placeholder="Selecionar Tarifário"
                                value={this.state.tarifario}
                                onChange={(e) => {
                                  this.onTar(e);
                                }}
                                options={this.state.tarifariosFinal}
                              >
                                {/* {(!this.state.tarifarios || this.state.tarifarios.length <= 0) ? (<Option key="0">Sem dados!</Option>) : (
                                                            this.state.tarifarios.map(l => (<Option key={l.id}>{l.name}</Option>)))} */}
                              </Select>
                            </Form.Item>
                          </Col>

                          <Col span={this.state.planos.length > 1 ? 5 : 6}>
                            <Form.Item
                              label="Fidelização:"
                              name="fidelizacao"
                              required
                            >
                              <Select
                                id="fidelizacao"
                                onChange={this.onFidelizacao}
                                name="fidelizacao"
                                aria-required
                                value={this.state.fidelizacao}
                              >
                                <Option key="24">24 meses</Option>
                                <Option key="30">30 meses</Option>
                                <Option key="36">36 meses</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {(this.state.tamanhoTar != 0 && !item.valor2) ||
                          this.state.valor2 != "0" ? (
                            <Col span={this.state.planos.length > 1 ? 5 : 6}>
                              <Form.Item label="Valor:" required>
                                <Input
                                  type="text"
                                  value={this.state.valor}
                                  placeholder="Insira um valor"
                                  required
                                />
                              </Form.Item>
                            </Col>
                          ) : (
                            <Col span={this.state.planos.length > 1 ? 5 : 6}>
                              <Form.Item label="Valor:" required>
                                <Input
                                  type="text"
                                  required
                                  placeholder="Insira um valor"
                                  value={this.state.valor}
                                  onChange={this.onValor}
                                />
                              </Form.Item>
                            </Col>
                          )}

                          <Col span={this.state.planos.length > 1 ? 5 : 6}>
                            <Form.Item label="Serviço:" required>
                              <Input
                                type="text"
                                required
                                value={this.state.servico}
                                onChange={this.onServico}
                              />
                            </Form.Item>
                          </Col>
                          {this.state.planos.length > 1 && (
                            <Col span={3} style={{ textAlign: "center" }}>
                              <Form.Item label=" ">
                                <CloseOutlined
                                  style={{
                                    fontSize: "20px",
                                    color: "#F94D5A",
                                    cursor: "pointer",
                                    alignItems: "center",
                                  }}
                                  onClick={() => {
                                    this.eliminarLinha(item.id);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      )}
                    </>
                  ))}
                  <Space style={{ paddingTop: 0, paddingBottom: 7 }}>
                    <PlusCircleFilled
                      style={{
                        fontSize: "20px",
                        color: "#F94D5A",
                        cursor: "pointer",
                      }}
                      onClick={this.adicionarLinha}
                    />

                    <h6 style={{ paddingTop: 7 }}>Adicionar tarifário</h6>
                  </Space>

                  <Form.Item for="obs" label="Obs:">
                    <TextArea
                      rows={4}
                      value={this.state.obs}
                      onChange={this.onObs}
                    />
                  </Form.Item>

                  <Form.Item style={{ textAlign: "right", right: -15 }}>
                    <Button danger htmlType="submit">
                      {this.state.login === "User"
                        ? "Enviar para aprovação"
                        : "Enviar para o cliente"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Proposta;
