import { USER_LOGIN, USER_RESET, LOGIN, LOGOUT } from "./actionTypes";

/**
 * Parameters for the user loggedin
 * @param {} data Informations of the user logged in
 *
 */
export const userLogin = (data) => ({
  type: USER_LOGIN,
  id: data.id,
  tipo: data.tipo,
  name: data.name,
  email: data.email,
  last_updated: data.last_updated,
  img: data.img,
});

/**
 * User Logout erases all info
 */
export const userReset = () => ({
  type: USER_RESET,
  id: "",
  name: "",
  email: "",
  telemovel: "",
  tipo: "",
  last_updated: "",
  img: "",
});

/**
 * Selects what type of user logs in
 * @param {string} name Type of User
 *
 */
export const loginLogin = (name) => ({
  type: LOGIN,
  name: name,
});

/**
 * Erases all info of login
 */
export const loginReset = () => ({
  type: LOGOUT,
  name: "",
});

/**
 * Updates data in redux store
 * Used by Login
 * @param {data} data
 * @returns userLogin, loginLogin
 */
export function updateData(data) {
  return function (dispatch) {
    dispatch(userLogin(data));
    dispatch(loginLogin(data.tipo, data.name));
  };
}

/**
 * Resets state of Proposta
 * @returns resetProposta
 */
export function resetProposta() {
  return function (dispatch) {
    dispatch(resetProposta());
  };
}
