import { Col, Row, Card, Space } from "antd";
import React, { Component } from "react";
import axios from "axios";
import "../css/base.css";
import {
  RadialChart,
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  LabelSeries,
  ChartLabel,
  LineSeries,
} from "react-vis";
import moment from "moment";
import {
  API_URL_PROPOSTAS,
  API_URL_TARIFARIO,
  API_URL_CLIENTES,
} from "../constants";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import "moment/locale/pt";

/** Estatistic Data */
class Estatisticas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propostas: [],
    };
  }

  /**
   * Component mount for the 1st time
   */
  componentDidMount() {
    this.getData();
  }
  /**
   * Get data from API - Propostas
   */
  getData() {
    axios.get(API_URL_PROPOSTAS).then((res) => {
      var data = res.data;
      this.setState({ propostas: data });
    });
  }

  /**
   * Turn data into objects
   * @param {list} m Months
   * @returns obj
   */
  filtroMesEnviadas = (m) => {
    const propostas = this.state.propostas;
    let p = propostas.filter((pr) => pr.status == "APROVADO");

    var obj = {};
    let s = p.filter((r) => moment(r.criado, "YYYY-MM-DD").month() + 1 === m);
    if (s.length >= 1) {
      obj.x = m;
      obj.y = s.length;
      return obj;
    } else {
      obj.x = m;
      obj.y = 0;
      return obj;
    }
  };

  /**
   * Turn data into objects
   * @param {list} m Months
   * @returns obj
   */
  filtroMesAceites = (m) => {
    const propostas = this.state.propostas;

    let p = propostas.filter((pr) => pr.accepted == true);
    var obj = {};
    let s = p.filter((r) => moment(r.criado, "YYYY-MM-DD").month() + 1 === m);
    if (s.length >= 1) {
      obj.x = m;
      obj.y = s.length;
      return obj;
    } else {
      obj.x = m;
      obj.y = 0;
      return obj;
    }
  };
  /**
   * Map data along the months
   * data - Propostas enviadas
   */
  dataPropostasEnviadas() {
    const currentYear = moment().year();
    const mesAno = Array.from({ length: 12 }, (_, index) => ({
      mes: index + 1,
      ano: currentYear,
    }));

    return mesAno.map((item) => this.filtroMesEnviadas(item.mes));
  }

  dataPropostasEnviadas2() {
    const currentYear = moment().year();
    const mesAno = Array.from({ length: 12 }, (_, index) => ({
      mes: index + 1,
      ano: currentYear,
    }));

    return mesAno.map((item) => this.filtroMesEnviadas2(item.mes));
  }

  filtroMesEnviadas = (m) => {
    const propostas = this.state.propostas.filter(
      (pr) => pr.status === "APROVADO"
    );

    const obj = {
      x: moment()
        .set("month", m - 1)
        .locale("pt")
        .format("MMMM"),
      y: 0,
    };

    const s = propostas.filter(
      (r) => moment(r.criado, "YYYY-MM-DD").month() === m - 1
    );

    obj.y = s.length;

    return obj;
  };

  filtroMesEnviadas2 = (m) => {
    const propostas = this.state.propostas;

    const obj = {
      x: moment()
        .set("month", m - 1)
        .locale("pt")
        .format("MMMM"),
      y: 0,
    };

    const s = propostas.filter(
      (r) => moment(r.criado, "YYYY-MM-DD").month() === m - 1
    );

    obj.y = s.length;

    return obj;
  };

  /**
   * Map data along the months
   * data - Propostas aceites
   */
  dataPropostasAceites() {
    const currentYear = moment().year();
    const mesAno = Array.from({ length: 12 }, (_, index) => ({
      mes: index + 1,
      ano: currentYear,
    }));

    return mesAno.map((item) => this.filtroMesEnviadas(item.mes));
  }

  /**
   * Render components
   */
  render() {
    const propostas = this.state.propostas;
    let penv = propostas.filter((pr) => pr.status != "");
    let paccept = propostas.filter((pr) => pr.status == "APROVADO");
    // let paccept = propostas.filter((pr) => pr.accepted == true);

    const dataPropostas = this.dataPropostasEnviadas();
    const dataPropostas2 = this.dataPropostasEnviadas2();

    const chartData = {
      labels: dataPropostas.map((obj) => obj.x),
      datasets: [
        {
          label: "Propostas aprovadas",
          data: dataPropostas.map((obj) => obj.y),
          backgroundColor: "rgba(249, 77, 90, 0.6)",
          borderColor: "rgba(249, 77, 90, 1)",
          borderWidth: 1,
        },
      ],
    };

    const chartData2 = {
      labels: dataPropostas2.map((obj) => obj.x),
      datasets: [
        {
          label: "Propostas enviadas",
          data: dataPropostas2.map((obj) => obj.y),
          backgroundColor: "rgba(249, 77, 90, 0.6)",
          borderColor: "rgba(249, 77, 90, 1)",
          borderWidth: 1,
        },
      ],
    };

    return (
      <div className="estatisticas">
        <Space direction="vertical" align="center">
          <Card className="list_card" style={{ width: 800, height: 500 }}>
            <h7>Propostas Aceites</h7>
            <p>
              <h8>{paccept.length}</h8>
            </p>
            <p>
              <Bar
                data={chartData}
                options={{
                  scales: {
                    x: {
                      type: "category",
                      position: "bottom",
                      title: {
                        display: true,
                        text: "Meses",
                      },
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Número de Propostas",
                      },
                    },
                  },
                }}
              />
            </p>
          </Card>

          <Card className="list_card" style={{ width: 800, height: 500 }}>
            <h7>Propostas Enviadas</h7>
            <p>
              <h8>{penv.length}</h8>
            </p>
            <p>
              <Bar
                data={chartData2}
                options={{
                  scales: {
                    x: {
                      type: "category",
                      position: "bottom",
                      title: {
                        display: true,
                        text: "Meses",
                      },
                    },
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Número de Propostas",
                      },
                    },
                  },
                }}
              />
            </p>
          </Card>
        </Space>
      </div>
    );
  }
}

export default Estatisticas;
