import React, { Component } from "react";
import {
  Layout,
  Menu,
  Row,
  Col,
  Avatar,
  Form,
  Input,
  Button,
  notification,
} from "antd";
import "../css/base.css";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import Listagem from "./list";
import { loginReset, userReset, updateData } from "../actions";
import { connect } from "react-redux";
import Proposta from "./newProposta";
import PersistedStore from "../store";
import Help from "./help";
import bcrypt from "bcryptjs";
import { Navigate } from "react-router-dom";
import Inicio from "./inicio";
import { API_URL_USERS } from "../constants";
const { SubMenu } = Menu;
const { Content } = Layout;

/**
 * Class of the User(Comercial)
 * Page of the user
 */
class User extends Component {
  constructor(props) {
    super(props);
    /**
     * State of the component */
    this.state = {
      dash: "dash",
      name: "",
      email: "",
      password: "",
      logout: false,
    };
  }

  /**
   * Rendering function whrn the component is mounted
   * Occurs only once
   */
  componentDidMount() {
    this.data();
  }

  /**
   * Getting information from Redux store
   *  @param store PersistedStore Redux Store
   * @param state val  State  of user in the Redux store
   * @param state login  State  of login in the Redux store
   */
  data() {
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().userState;
    const login = store.getState().loginState;

    this.setState({
      login: login.name,
      id: val.id,
      email: val.email,
      name: val.name,
      img: val.img,
    });
  }

  /**
   * Updates the variable dash
   * @param string dash Variable dash used in state
   */
  alt(dash) {
    this.setState({ dash: dash });
  }

  /**
   * Refering to Component Listagem - lists data in a table
   * @param string info Defines what to be listed
   */
  listagem(info) {
    return <Listagem info={info} />;
  }

  /**
   * Manageing which component to be renderes based on State
   * @param string dash Variable dash used in state
   */
  dashboard() {
    let dash = this.state.dash;
    switch (dash) {
      case "dash":
        return <Inicio info="dash" tipo="user" />;
      case "propostas":
        return this.listagem("propostas");
      case "clientes":
        return this.listagem("clientes");
      case "novaproposta":
        return <Proposta />;
      case "ajuda":
        return <Help />;
      case "perfil":
        return this.perfil();

      default:
        return this.inicio();
    }
  }

  /**
   * Dashboard of the User
   * @component Button creates newProposta
   * listagem - list current Propostas not aproved */
  inicio() {
    return (
      <>
        <Button onClick={() => this.alt("novaproposta")}></Button>
        {this.listagem("propostas")}
      </>
    );
  }

  /**
   * Updates the variable email in state
   * @param  {event} e
   */
  onEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   * Updates the variable password  in state
   * @param  {event} e
   */
  onPass = (e) => {
    const hashedPassword = bcrypt.hashSync(
      e.target.value,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    this.setState({ password: hashedPassword });
  };

  /**
   * Updates the variable nome of the client in state
   * @param  {event} e
   */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };

  /**
   * Opens a box with notification
   * @param {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };

  /**
   * Post user data changes to the API
   * @param state this.state State of the Component
   * @param integer this.state.id ID of the user logged in
   * */
  edit = () => {
    axios.put(API_URL_USERS + this.state.id, this.state).then(() => {
      this.openNotification("Os dados foram atualizados com sucesso!");
    });
  };

  /**
   * Makes the logout from the App */
  logout = (e) => {
    this.props.loginReset();
    this.props.userReset();
    this.setState({ logout: true });
  };
  /**
   * Component to edit User information */
  perfil() {
    return (
      <Row style={{ paddingTop: 50 }} className="row justify-content-center ">
        <Col></Col>
        <Col span={7}>
          <div>
            <h4>Perfil</h4>
          </div>
          <Form
            style={{ paddingTop: 25 }}
            layout="vertical"
            onFinish={this.edit}
          >
            <Form.Item label="Nome" name="nome">
              <Input
                type="text"
                name="name"
                onChange={this.onNome}
                defaultValue={this.state.name}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  pattern: new RegExp("[a-z.]*[@]tectank.pt"),
                  message: "Formato de email incorreto.",
                },
              ]}
            >
              <Input
                type="email"
                onChange={this.onEmail}
                defaultValue={this.state.email}
              />
            </Form.Item>

            <Form.Item label="Password" name="password">
              <Input.Password onChange={this.onPass} />
            </Form.Item>

            <Form.Item style={{ textAlign: "right", right: -15 }}>
              <Button htmlType="submit">Guardar</Button>
            </Form.Item>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    );
  }

  /**
   * Rendering component Menus */
  render() {
    return (
      <div>
        {this.state.login === "" ? <Navigate to="/" /> : ""}

        <Layout>
          <Menu
            className="upbar"
            mode="horizontal"
            triggerSubMenuAction={"click"}
          >
            <Menu.Item
              key="m1"
              onClick={() => {}}
              icon={<Avatar size={30} shape="square" src={""} />}
            ></Menu.Item>
            <Menu.Item
              key="m2"
              onClick={() => {
                this.alt("dash");
              }}
            >
              Dashboard
            </Menu.Item>
            <Menu.Item
              key="m3"
              onClick={() => {
                this.alt("propostas");
              }}
            >
              Propostas
            </Menu.Item>
            <Menu.Item
              key="m4"
              onClick={() => {
                this.alt("clientes");
              }}
            >
              Clientes
            </Menu.Item>
            <Menu.Item
              key="m6"
              onClick={() => {
                this.alt("ajuda");
              }}
            >
              Ajuda
            </Menu.Item>

            <Menu.Item key="m8" style={{ marginLeft: "auto" }}>
              <SubMenu key="sub1" title={this.state.name}>
                <Menu.Item
                  key="subsub1"
                  icon={<UserOutlined style={{ color: "#F94D5A" }} />}
                  onClick={() => {
                    this.alt("perfil");
                  }}
                >
                  Perfil
                </Menu.Item>
                <Menu.Item
                  key="subsub2"
                  icon={<LogoutOutlined style={{ color: "#F94D5A" }} />}
                  onClick={this.logout}
                >
                  {this.state.logout === true ? <Navigate to="/" /> : "Logout"}
                </Menu.Item>
              </SubMenu>
            </Menu.Item>
          </Menu>
          <Layout className="lay">
            <Content>{this.dashboard()}</Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

/**
 * Maps variabels to redux store*/
function mapStateToProps(state) {
  return {
    id: state.id,
    name: state.name,
    email: state.email,
    telemovel: state.telemovel,
    img: state.img,
    password: state.password,
  };
}
/**
 * Maps functions to redux store*/
function mapDispatchToProps(dispatch) {
  return {
    loginReset: () => dispatch(loginReset()),
    userReset: () => dispatch(userReset()),
    updateData: (data) => dispatch(updateData(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
