import React, { Component } from "react";
import "../css/base.css";
import axios from "axios";
import { Button, Form, Input, notification, Row, Col } from "antd";
import bcrypt from "bcryptjs";
import { API_URL_EMAIL_USER, API_URL_USERS } from "../constants";

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      email: "",
      password: "",
      telemovel: "",
    };
  }

  /**
   * Get data from previous component
   */
  componentDidMount() {
    if (this.props.data) {
      const { id, name, email, password, telemovel } = this.props.data;
      if (telemovel != 0) {
        this.setState({ id, name, email, password, telemovel });
      } else {
        this.setState({ id, name, email, password });
      }
    } else {
      const pass = bcrypt.hashSync("password", "$2a$10$CwTycUXWue0Thq9StjUM0u");
      this.setState({ password: pass });
    }
  }

  /**
   * Updates the variable name  in state
   *   @param  {event} e
   * */
  onNome = (e) => {
    this.setState({ name: e.target.value });
  };

  /**
   * Updates the variable email  in state
   * @param  {event} e
   */
  onEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  /**
   *  Updates the variable telemovel of the client in state
   * @param  {event} e
   *
   */
  onTel = (e) => {
    this.setState({ telemovel: e.target.value });
  };

  /**
   * Resets the password
   * @param  {event} e
   */
  onPass = () => {
    const pass = bcrypt.hashSync("password", "$2a$10$CwTycUXWue0Thq9StjUM0u");
    this.setState({ password: pass });

    axios.put(API_URL_USERS + this.state.id, { password: pass }).then((res) => {
      this.openNotification("A palavra-passe foi reposta com sucesso.");
      this.props.resetState();
      this.props.toggle();
    });
  };

  /**
   * Edit user detais and send an API PUT Request
   * @param {*} e
   */
  edit = (e) => {
    axios.put(API_URL_USERS + this.state.id, this.state).then((res) => {
      this.openNotification("O Utilizador foi editado com sucesso.");
      this.props.resetState();
      this.props.toggle();
    });
  };

  /**
   * Create user  and send an API POST Request
   * @param {*} e
   */
  create = (e) => {
    axios.post(API_URL_USERS, this.state).then((res) => {
      axios.post(API_URL_EMAIL_USER, { email: this.state.email });
      this.openNotification("O Utilizador foi criado com sucesso.");
      this.props.resetState();
      this.props.menu("inicio");
    });
  };

  /**
   * Opens a notification bar with text
   * @param {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };
  render() {
    return (
      <div>
        <Row className="row justify-content-center ">
          <Col></Col>
          <Col span={7}>
            <div>
              <h4>Utilizador</h4>
            </div>
            <Form
              layout="vertical"
              onFinish={this.props.data ? this.edit : this.create}
              fields={[
                {
                  name: ["email"],
                  value: this.state.email,
                },
                {
                  name: ["telemovel"],
                  value: this.state.telemovel,
                },
              ]}
            >
              <Form.Item required label="Nome:">
                <Input
                  required
                  type="text"
                  value={this.state.name}
                  placeholder="João Silva"
                  onChange={this.onNome}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@tectank.pt$"
                    ),
                    message: "Formato de email inválido.",
                  },
                ]}
              >
                <Input
                  type="email"
                  name="email"
                  placeholder="joaosilva@tectank.pt"
                  value={this.state.email}
                  onChange={this.onEmail}
                />
              </Form.Item>
              <Form.Item
                name="telemovel"
                label="Telemóvel:"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      "(9[1236][0-9]) ?([0-9]{3}) ?([0-9]{3})"
                    ),
                    message: "Verifique o numero de Telemóvel.",
                  },
                  {
                    pattern: new RegExp(/\d+/g),
                    message: "Insira apenas números",
                  },
                ]}
              >
                <Input
                  name="telemovel"
                  type="tel"
                  minLength={9}
                  maxLength={9}
                  required
                  placeholder="911111111"
                  value={this.state.telemovel}
                  onChange={this.onTel}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Button onClick={this.onPass}>Repor Password</Button>
              </Form.Item>
              <Form.Item style={{ textAlign: "right" }}>
                <Button htmlType="submit">Gravar</Button>
              </Form.Item>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </div>
    );
  }
}

export default NewUser;
