import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import {loginReducer} from './loginReducer';

/** Export of Reducers from Redux*/
export const Reducers = combineReducers({

  userState: userReducer,
  loginState: loginReducer,

});
