import React, { Component } from "react";
import "../css/base.css";
import axios from "axios";
import {
  Card,
  Row,
  Col,
  List,
  Space,
  Select,
  Button,
  Input,
  Popover,
  Segmented,
  notification,
  Empty,
} from "antd";
import DeleteModal from "./deletemodal.jsx";
import {
  API_URL_PROPOSTAS,
  API_URL_CLIENTES,
  API_URL_ADMIN,
  API_URL_SADMIN,
  API_URL_USERS,
  API_URL_TARIFARIO,
  API_URL_PLANOS,
} from "../constants";
import PersistedStore from "../store";
import Proposta from "./newProposta";
import {
  PlusCircleFilled,
  FilterFilled,
  ReloadOutlined,
} from "@ant-design/icons";
import NewDataModal from "./NewDataModal";
import { styled } from "@mui/material/styles";
import NewCliente from "./newCliente";
import NewAdmin from "./NewAdmin";
import NewUser from "./NewUser";
import NewTarifario from "./newTarifario";
import LeaveModal from "./LeaveModal";

const { Option } = Select;

/**
 * List of all data
 */
class Listagem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initLoading: true,
      loading: false,
      data: [],
      list: [],
      info: "",
      users: [],
      propostas: [],
      tarifarios: [],
      dash: "dash",
      pesquisa: "",
      nome: "",
      dataSource: [],
      clientes: [],
      sadmin: [],
      admin: [],
      login: "",
      filtro: "",
      clientesNif: [],
      clientes2: [],
      nif: "",
    };
    this.data = this.data.bind(this);
  }

  componentDidMount() {
    this.dataa();
    this.fetchdata();
    this.data();
  }

  /**
   * Get type of login at Redux Store
   */
  dataa() {
    const store = PersistedStore.getDefaultStore().store;
    const val = store.getState().loginState;

    this.setState({ login: val.name, dash: "" });
  }

  /**
   * API call to get all data of the value of the variable info.
   */
  data() {
    let info = this.props.info;
    let url = "";
    switch (info) {
      case "propostas":
        url = API_URL_PROPOSTAS;
        break;
      case "clientes":
        url = API_URL_CLIENTES;
        break;
      case "admin":
        url = API_URL_ADMIN;
        break;
      case "users":
        url = API_URL_USERS;
        break;
      case "tar":
        url = API_URL_TARIFARIO;
        break;
      default:
      // console.log("Sem Correspondência.");
    }
    axios.get(url).then((res) => {
      var data = res.data;
      let propostas = [];

      data.forEach((element) => {
        this.state.clientes.forEach((element2) => {
          if (element.client_id == element2.id) {
            const proposta = {
              accept: element.accepted,
              client_id: element.client_id,
              criado: element.criado,
              file: element.file,
              id: element.id,
              id_plano: element.id_plano,
              last_updated: element.last_updated,
              obs: element.obs,
              responsavel: element.responsavel,
              status: element.status,
              nif: element2.nif + "",
            };
            propostas.push(proposta);
          }
        });
      });
      this.setState({
        data: data,
        dataSource: data,
        info: this.props.info,
        filtro: "",
        pesquisa: "",
      });
      this.state.clientesNif = propostas;
      this.state.clientes2 = propostas;
      this.forceUpdate();
    });
  }

  /**
   * Fetch needed data
   */
  fetchdata() {
    axios.get(API_URL_SADMIN).then((res) => {
      var data = res.data;
      this.setState({ sadmin: data });
    });
    axios.get(API_URL_ADMIN).then((res) => {
      var data = res.data;
      this.setState({ admin: data });
    });

    axios.get(API_URL_USERS).then((res) => {
      var data = res.data;
      this.setState({ users: data });
    });

    axios.get(API_URL_PROPOSTAS).then((res) => {
      var data = res.data;
      this.setState({ propostas: data });
    });
    axios.get(API_URL_TARIFARIO).then((res) => {
      var data = res.data;
      this.setState({ tarifarios: data });
    });
    axios.get(API_URL_CLIENTES).then((res) => {
      var data = res.data;
      this.setState({ clientes: data });
    });
  }

  /**
   * Fetch new data on the API
   * @param {*} e
   */
  resetState = (e) => {
    this.fetchdata();
    this.data();
    this.setState({
      pesquisa: "",
      filtro: "",
    });
  };

  /**
   * Get tarifario id of selected proposta of a client
   * @param {int} e
   * @returns ID of tarifario
   */
  tarifario = (e) => {
    let proposta = this.state.propostas;
    let tarifarios = this.state.tarifarios;

    if (!proposta || proposta.length === 0) {
      return "";
    } else {
      if (!tarifarios || tarifarios.length === 0) {
        return "";
      } else {
        let p = proposta.filter((p) => p.client_id === e);
        let u = p.pop();

        let tarifario = tarifarios.filter((t) => t.id === u.tarifario);
        let tar = tarifario.pop();
        return tar.id;
      }
    }
  };

  /**
   * Get status of proposta of e client
   * @param {int} e client_id
   * @returns status string
   */
  statustarifario = (e) => {
    let proposta = this.state.propostas;
    if (!proposta || proposta.length === 0) {
      return "";
    } else {
      let user = proposta.filter((p) => p.client_id === e);

      let u = user.pop();
      return u.status;
    }
  };

  /**
   * Get name of Client
   * @param {int} e
   * @returns name
   */
  onNomeCliente = (e) => {
    let cliente = this.state.clientes;
    if (!cliente || cliente.length === 0) {
      return "";
    } else {
      let user = cliente.filter((p) => p.id === e);

      let u = user.pop();
      return u.name;
    }
  };

  onNif = (e) => {
    let cliente = this.state.clientes;
    if (!cliente || cliente.length === 0) {
      return "";
    } else {
      let user = cliente.filter((p) => p.nif === e);
      let u = user.pop();

      return u.nif;
    }
  };

  onNifClientes = (e) => {
    let cliente = this.state.clientes;
    if (!cliente || cliente.length === 0) {
      return "";
    } else {
      let user = cliente.filter((p) => toString(p.nif) === e);
      let u = user.pop();

      return u.nif;
    }
  };

  /**
   * Rendering of badges
   * @param {string} s
   * @returns component
   */
  badge(s) {
    switch (s) {
      case "EM APROVACAO": {
        const Div = styled("div")(({ theme }) => ({
          backgroundColor: "#F5D994",
          cursor: "pointer",
          alignContent: "center",
          fontWeight: "bold",
          color: "#646464",
        }));

        return <Div>EM APROVAÇÃO</Div>;
      }

      case "REPROVADO": {
        const Div = styled("div")(({ theme }) => ({
          backgroundColor: "#F57777",
          alignContent: "center",
          fontWeight: "bold",
          cursor: "pointer",
          color: "#646464",
        }));

        return <Div>{s}</Div>;
      }

      case "ARQUIVADO": {
        const Div = styled("div")(({ theme }) => ({
          backgroundColor: "#BAB3B3",
          alignContent: "center",
          fontWeight: "bold",
          color: "#646464",

          cursor: "pointer",
        }));
        return <Div>{s}</Div>;
      }

      case "APROVADO": {
        const Div = styled("div")(({ theme }) => ({
          backgroundColor: "#57F163",
          fontWeight: "bold",
          color: "#646464",
          alignContent: "center",
          cursor: "pointer",
        }));
        return <Div>{s}</Div>;
      }

      default:
        break;
    }
  }

  /**
   * Get id of tarifário
   * @param {list} e
   * @returns id
   */
  tari = (e) => {
    // if (this.state.tarifarios.length !== 0 && e.length !== 0) {
    //   let i = 0
    //   while (i < e.length) {
    //     let obj = this.state.tarifarios.filter(p => p.id === parseInt(e[i]))
    //     if (obj.length !== 0) {
    //       let o = obj.pop()
    //       if (o !== undefined) {
    //         if (o.name !== undefined) {
    //           return o.id.toString()
    //         } return ""
    //       } return ""
    //     }
    //     i++
    //   }
    //   return ""
    // }
  };

  /**
   * Delete selected data
   * @param {object} data
   * @param {int} id
   * @returns
   */
  onUser = (data, id) => {
    return this.state.login === "User" ? (
      ""
    ) : (
      <DeleteModal info={data} id={id} resetState={this.resetState} />
    );
  };

  /**
   * Update status of proposta
   * @param {string} val
   * @param {int} id
   */
  updateStatus(val, id) {
    if (val === "EM APROVAÇÃO") val = "EM APROVACAO";
    axios.put(API_URL_PROPOSTAS + id, { status: val }).then(() => {
      this.resetState();
    });
  }

  /**
   * Rendering the data requested
   * @param {string} data
   * @returns component list
   */
  dataRender(data) {
    let dr = this.state.data;
    let d = dr.reverse();
    const dataSource = this.state.dataSource;
    const s = 4;
    switch (data) {
      case "propostas": {
        if (!d || d.length === 0) return <Empty />;
        else
          return (
            <>
              <Card className="list_menu">
                <Row>
                  <Col span={3} className="list_nome">
                    CLIENTE
                  </Col>
                  <Col span={s}>NIF</Col>
                  <Col span={s}>CRIADO </Col>
                  <Col span={s}>RESPONSÁVEL</Col>
                  <Col span={5} className="list_badge">
                    STATUS
                  </Col>
                  <Col span={3} className="list_acao">
                    AÇÕES
                  </Col>
                </Row>
              </Card>

              <List
                pagination={{ pageSize: 5 }}
                grid={{ gutter: 10, column: 1 }}
                dataSource={[...dataSource]}
                renderItem={(p) => (
                  <List.Item className="list_item">
                    <Card className="list_card justify-content-center">
                      <Row>
                        <Col span={3} className="list_nome">
                          {this.onNomeCliente(p.client_id)}
                        </Col>
                        {this.state.clientesNif
                          .filter((itemN) => itemN.id_plano == p.id_plano)
                          .map((itemN) => {
                            this.state.nif = itemN.nif;
                          })}
                        <Col span={s}>{this.state.nif}</Col>
                        <Col span={s}>{p.criado} </Col>
                        <Col span={s}>{p.responsavel}</Col>
                        <Col span={5} className="list_badge">
                          {this.state.login === "User" ? (
                            this.badge(p.status)
                          ) : (
                            <Popover
                              content={
                                <Segmented
                                  onChange={(event) =>
                                    this.updateStatus(event, p.id)
                                  }
                                  id={p.id}
                                  value={this.badge(p.status).props.children}
                                  options={[
                                    "APROVADO",
                                    "REPROVADO",
                                    "ARQUIVADO",
                                    "EM APROVAÇÃO",
                                  ]}
                                />
                              }
                              trigger="click"
                            >
                              {this.badge(p.status)}
                            </Popover>
                          )}
                        </Col>

                        <Col span={3} className="list_acao">
                          <Space size={20}>
                            <NewDataModal
                              info={data}
                              data={p}
                              resetState={this.resetState}
                              notify={this.openNotification}
                            />
                            {this.onUser(data, p.id)}
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                )}
              />
            </>
          );
      }
      case "clientes": {
        if (!d || d.length === 0) return <Empty />;
        else {
          return (
            <>
              <Card className="list_menu">
                <Row>
                  <Col className="list_nome" span={5}>
                    CLIENTE
                  </Col>
                  <Col className="list_nome" span={5}>
                    NIF
                  </Col>
                  <Col span={5}>TELEMÓVEL</Col>
                  <Col span={5}>EMAIL</Col>
                  <Col span={4}>AÇÕES</Col>
                </Row>
              </Card>

              <List
                pagination={{ pageSize: 5 }}
                grid={{ gutter: 10, column: 1 }}
                dataSource={[...dataSource]}
                renderItem={(p) => (
                  <List.Item>
                    <Card className="list_card">
                      <Row>
                        <Col className="list_nome" span={5}>
                          {p.name}
                        </Col>
                        <Col className="list_nome" span={5}>
                          {p.nif}
                        </Col>
                        <Col span={5}>{p.telemovel} </Col>
                        <Col span={5}>{p.email}</Col>

                        <Col span={4}>
                          <Space size={20}>
                            <NewDataModal
                              info={data}
                              data={p}
                              resetState={this.resetState}
                              notify={this.openNotification}
                            />
                            {this.onUser(data, p.id)}
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                )}
              />
            </>
          );
        }
      }
      case "admin": {
        if (!d || d.length === 0) return <Empty />;
        else
          return (
            <>
              <h4>Administradores</h4>
              <Card className="list_menu">
                <Row gutter={[8, 8]}>
                  <Col className="list_nome" span={8}>
                    NOME
                  </Col>
                  <Col span={8}>EMAIL</Col>
                  <Col className="list_acao" span={8}>
                    AÇÕES
                  </Col>
                </Row>
              </Card>

              <List
                pagination={{ pageSize: 5 }}
                grid={{ gutter: 10, column: 1 }}
                dataSource={[...dataSource]}
                renderItem={(p) => (
                  <List.Item>
                    <Card className="list_card">
                      <Row gutter={[8, 8]}>
                        <Col className="list_nome" span={8}>
                          {p.name}{" "}
                        </Col>
                        <Col span={8}>{p.email}</Col>
                        <Col className="list_acao" span={8}>
                          <Space size={20}>
                            <NewDataModal
                              info={data}
                              data={p}
                              resetState={this.resetState}
                              notify={this.openNotification}
                            />
                            {this.onUser(data, p.id)}
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                )}
              />
            </>
          );
      }
      case "users": {
        if (!d || d.length === 0) return <Empty />;
        else
          return (
            <>
              <h4>Utilizadores</h4>
              <Card className="list_menu">
                <Row gutter={[8, 8]}>
                  <Col className="list_nome" span={8}>
                    NOME
                  </Col>
                  <Col span={8}>EMAIL</Col>
                  <Col className="list_badge" span={8}>
                    AÇÕES
                  </Col>
                </Row>
              </Card>

              <List
                pagination={{ pageSize: 5 }}
                grid={{ gutter: 10, column: 1 }}
                dataSource={[...dataSource]}
                renderItem={(p) => (
                  <List.Item>
                    <Card className="list_card">
                      <Row gutter={[8, 8]}>
                        <Col className="list_nome" span={8}>
                          {p.name}{" "}
                        </Col>
                        <Col span={8}>{p.email}</Col>
                        <Col className="list_badge" span={8}>
                          <Space size={20}>
                            <NewDataModal
                              info={data}
                              data={p}
                              resetState={this.resetState}
                              notify={this.openNotification}
                            />
                            <DeleteModal
                              info={data}
                              id={p.id}
                              resetState={this.resetState}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                )}
              />
            </>
          );
      }

      case "tar": {
        if (!d || d.length === 0) return <Empty />;
        else
          return (
            <>
              <h4>Tarifários</h4>
              <Card className="list_menu">
                <Row gutter={[8, 8]}>
                  <Col className="list_nome" span={4}>
                    TARIFÁRIO
                  </Col>
                  <Col span={4}>DADOS MÓVEIS</Col>
                  <Col span={4}>QUANTIDADE</Col>
                  <Col span={4}>VALOR</Col>
                  <Col className="list_acao" span={4}>
                    AÇÕES
                  </Col>
                </Row>
              </Card>

              <List
                pagination={{ pageSize: 5 }}
                grid={{ column: 1 }}
                dataSource={[...dataSource]}
                renderItem={(p) => (
                  <List.Item className="list_item">
                    <Card className="list_card justify-content-center">
                      <Row gutter={[8, 8]}>
                        <Col className="list_nome" span={4}>
                          {p.name}{" "}
                        </Col>
                        <Col span={4}>{p.dados_moveis}</Col>
                        <Col span={4}>{p.quantidade}</Col>
                        <Col span={4}>{p.valor}</Col>
                        <Col className="list_acao" span={s}>
                          <Space size={20}>
                            <NewDataModal
                              info={data}
                              data={p}
                              resetState={this.resetState}
                              notify={this.openNotification}
                            />
                            <DeleteModal
                              info={data}
                              id={p.id}
                              resetState={this.resetState}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </Card>
                  </List.Item>
                )}
              />
            </>
          );
      }

      default:
        break;
    }
  }

  /**
   * Update variable type of pesquisa
   * @param {event} e
   */
  onPesquisa = (e) => {
    this.setState({ pesquisa: e.target.value });
  };

  /**
   * Filtering of data
   * @param {} e
   */
  filtro = (e) => {
    const info = this.state.info;

    let nome = this.state.pesquisa.toLowerCase();
    let nif = parseInt(this.state.pesquisa);
    let dat = this.state.data;
    let clientNif = this.state.clientesNif;
    let v;
    if (nome.length > 0) {
      switch (info) {
        case "propostas": {
          if (!isNaN(nif)) {
            v = clientNif.filter((p) =>
              p.nif
                ? p.nif.includes(nif)
                : this.onNif(p.nif).includes(toString(nif))
            );
          } else {
            v = clientNif.filter((p) =>
              p.name
                ? p.name.toLowerCase().includes(nome)
                : this.onNomeCliente(p.client_id).toLowerCase().includes(nome)
            );
          }
          if (v.length === 0) {
            v = [];
          }
          break;
        }
        case "clientes": {
          if (!isNaN(nif)) {
            v = this.state.clientes.filter((p) =>
              p.nif.toString().toLowerCase().includes(nif)
            );
          } else {
            v = this.state.clientes.filter((p) =>
              p.name.toString().toLowerCase().includes(nome)
            );
          }
          if (v.length === 0) {
            v = [];
          }
          break;
        }
        default: {
          v = dat.filter((p) => p.name.toString().toLowerCase().includes(nome));
          if (v.length === 0) {
            v = [];
          }
          break;
        }
      }
    } else {
      v = dat;
    }

    this.setState({ dataSource: v });
  };

  /**
   * Filtering by status
   * @param {string} value
   */
  handleChange = (value) => {
    let nome = value;
    let dat = this.state.data;
    let r;
    if (value !== "nenhum") {
      if (value.length > 0) {
        r = dat.filter((p) => p.status === nome);
      } else {
        r = dat;
      }
    } else r = dat;

    this.setState({ dataSource: r, filtro: value });
  };

  /**
   * Component of list
   * @returns component list
   */
  lista() {
    const data = this.state.info;
    let dat = this.state.data;
    if (this.state.info !== this.props.info) {
      this.data();
    }

    let criar_novo;
    switch (data) {
      case "propostas":
        criar_novo = (
          <>
            <Space>
              <Input
                style={{ width: 250 }}
                placeholder="Pesquisar por Cliente ou NIF"
                value={this.state.pesquisa}
                onChange={this.onPesquisa}
              />
              <FilterFilled
                onClick={this.filtro}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
              <ReloadOutlined
                onClick={this.resetState}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
            </Space>
            <Select
              style={{ width: 150 }}
              value={this.state.filtro}
              placeholder="Status"
              onChange={this.handleChange}
            >
              <Option value=""></Option>
              <Option value="APROVADO">APROVADO</Option>
              <Option value="EM APROVACAO">EM APROVAÇÃO</Option>
              <Option value="REPROVADO">REPROVADO</Option>
              <Option value="ARQUIVADO">ARQUIVADO</Option>
            </Select>
            <Space
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={() => {
                this.alt("proposta");
              }}
            >
              <PlusCircleFilled
                style={{ fontSize: "23px", color: "#F94D5A" }}
              />
              <h6 style={{ paddingTop: 10 }}>Criar Proposta</h6>
            </Space>
          </>
        );
        break;

      case "clientes":
        criar_novo = (
          <>
            <Space>
              <Input
                style={{ width: 250 }}
                value={this.state.pesquisa}
                placeholder="Pesquisar por Cliente ou NIF"
                onChange={this.onPesquisa}
              />
              <FilterFilled
                onClick={this.filtro}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
              <ReloadOutlined
                onClick={this.resetState}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
            </Space>
            <Space
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={() => {
                this.alt("cliente");
              }}
            >
              <PlusCircleFilled
                style={{ fontSize: "23px", color: "#F94D5A" }}
              />
              <h6 style={{ paddingTop: 10 }}>Criar Cliente</h6>
            </Space>
          </>
        );
        break;
      case "admin":
        criar_novo = (
          <>
            <Space>
              <Input
                style={{ width: 250 }}
                value={this.state.pesquisa}
                placeholder="Pesquisar por Administrador"
                onChange={this.onPesquisa}
              />
              <FilterFilled
                onClick={this.filtro}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
              <ReloadOutlined
                onClick={this.resetState}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
            </Space>

            <Space
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={() => {
                this.alt("admin");
              }}
            >
              <PlusCircleFilled
                style={{ fontSize: "23px", color: "#F94D5A" }}
              />
              <h6 style={{ paddingTop: 10 }}>Criar Administrador</h6>
            </Space>
          </>
        );
        break;
      case "users":
        criar_novo = (
          <>
            <Space>
              <Input
                style={{ width: 250 }}
                value={this.state.pesquisa}
                placeholder="Pesquisar por Utilizador"
                onChange={this.onPesquisa}
              />
              <FilterFilled
                onClick={this.filtro}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
              <ReloadOutlined
                onClick={this.resetState}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
            </Space>
            <Space
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={() => {
                this.alt("user");
              }}
            >
              <PlusCircleFilled
                style={{ fontSize: "23px", color: "#F94D5A" }}
              />
              <h6 style={{ paddingTop: 10 }}>Criar Utilizador</h6>
            </Space>
          </>
        );
        break;
      case "tar":
        criar_novo = (
          <>
            <Space>
              <Input
                style={{ width: 250 }}
                value={this.state.pesquisa}
                placeholder="Pesquisar por Tarifário"
                onChange={this.onPesquisa}
              />
              <FilterFilled
                onClick={this.filtro}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
              <ReloadOutlined
                onClick={this.resetState}
                style={{
                  fontSize: "23px",
                  color: "#F94D5A",
                  cursor: "pointer",
                }}
              />
            </Space>

            <Space
              style={{ cursor: "pointer", textAlign: "right" }}
              onClick={() => {
                this.alt("tar");
              }}
            >
              <PlusCircleFilled
                style={{ fontSize: "23px", color: "#F94D5A" }}
              />
              <h6 style={{ paddingTop: 10 }}>Criar Tarifário</h6>
            </Space>
          </>
        );
        break;
      default:
        break;
    }

    return (
      <div>
        <Row className="justify-content-center linha">
          <Space size={50}>{criar_novo}</Space>
        </Row>

        <Row className="row justify-content-center linha">
          <Col />
          <Col span={16}>
            <div>{this.dataRender(data)}</div>
          </Col>
          <Col />
        </Row>
      </div>
    );
  }

  /**
   * Opens a notification bar with text
   * @param {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };

  /**
   * Update variable dash in state
   * @param {string} dash
   */
  alt = (dash) => {
    this.setState({ dash: dash });
  };

  /**
   * In case of click 'criar proposta' change dashboard
   * @returns Component
   */
  dashboard() {
    let dash = this.state.dash;

    switch (dash) {
      case "proposta":
        return (
          <>
            <Row style={{ paddingTop: 25 }}>
              <Col span={4}></Col>
              <Col span={15} style={{ textAlign: "right" }}>
                <LeaveModal menu={this.alt} />
              </Col>
              <Col span={4}></Col>
            </Row>
            <Proposta
              menu={this.alt}
              resetState={this.resetState}
              notify={this.openNotification}
            />
          </>
        );
      case "cliente":
        return (
          <>
            <Row style={{ paddingTop: 25 }}>
              <Col span={4}></Col>
              <Col span={15} style={{ textAlign: "right" }}>
                <LeaveModal menu={this.alt} />
              </Col>
              <Col span={4}></Col>
            </Row>
            <NewCliente
              menu={this.alt}
              resetState={this.resetState}
              notify={this.openNotification}
            />
          </>
        );
      case "admin":
        return (
          <>
            <Row style={{ paddingTop: 25 }}>
              <Col span={4}></Col>
              <Col span={15} style={{ textAlign: "right" }}>
                <LeaveModal menu={this.alt} />
              </Col>
              <Col span={4}></Col>
            </Row>
            <NewAdmin
              menu={this.alt}
              resetState={this.resetState}
              notify={this.openNotification}
            />
          </>
        );
      case "user":
        return (
          <>
            <Row style={{ paddingTop: 25 }}>
              <Col span={4}></Col>
              <Col span={15} style={{ textAlign: "right" }}>
                <LeaveModal menu={this.alt} />
              </Col>
              <Col span={4}></Col>
            </Row>
            <NewUser
              menu={this.alt}
              resetState={this.resetState}
              notify={this.openNotification}
            />
          </>
        );
      case "tar":
        return (
          <>
            <Row style={{ paddingTop: 25 }}>
              <Col span={4}></Col>
              <Col span={15} style={{ textAlign: "right" }}>
                <LeaveModal menu={this.alt} />
              </Col>
              <Col span={4}></Col>
            </Row>
            <NewTarifario
              menu={this.alt}
              resetState={this.resetState}
              notify={this.openNotification}
            />
          </>
        );
      default:
        return this.lista();
    }
  }

  render() {
    return <>{this.dashboard()}</>;
  }
}

export default Listagem;
