import React, { Component } from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  Card,
  notification,
  Space,
  Alert,
} from "antd";
import { loginReset, userReset, updateData } from "../actions";
import "../css/base.css";
import { connect } from "react-redux";
import { API_URL_LOGIN } from "../constants";
import { Navigate } from "react-router-dom";
import bcrypt from "bcryptjs";
const { Footer } = Layout;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csrf: "",
      error: "",
      isAuthenticated: false,
      email: "",
      password: "",
      auth: null,
      info: "",
    };
  }

  componentDidMount() {
    this.props.userReset();
    this.props.loginReset();
    this.getSession();
  }

  defaultIfEmpty = (value) => {
    return value === "" ? "" : value;
  };

  /**
   * Get CSRF token from Backend
   */
  getCSRF = () => {
    fetch("/auth/csrf/", {
      credentials: "same-origin",
    })
      .then((res) => {
        let csrfToken = res.headers.get("X-CSRFToken");
        this.setState({ csrf: csrfToken });
      })
      .catch((err) => {});
  };

  /**
   * Get a session ID
   */
  getSession = () => {
    fetch("/auth/session/", { credentials: "same-origin" })
      .then((res) => res.json())
      .then((data) => {
        if (data.isAuthenticated) {
          this.setState({ isAuthenticated: true });
          this.props.updateData(data);
        } else {
          this.setState({ isAuthenticated: false });
          this.getCSRF();
        }
      })
      .catch((err) => {});
  };

  /**
   * Vereify if response is ok or hava an error
   * @param {json} response
   * @returns json
   */
  isResponseOk(response) {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      throw Error(response.statusText);
    }
  }

  /**
   * Open a notification bar
   * @param {string} texto
   */
  openNotification = (texto) => {
    notification.open({
      message: "Notificação",
      description: texto,
    });
  };

  /**
   * Manages login
   * @param {event} event
   */
  login = (event) => {
    fetch(API_URL_LOGIN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.state.csrf,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    })
      .then(this.isResponseOk)
      .then((data) => {
        this.props.updateData(data);
        this.setState({ isAuthenticated: true, login: data.tipo });
        this.openNotification("Login com Sucesso!");
      })
      .catch((err) => {
        this.openNotification("Verifique as suas credenciais de login!");
        this.setState({ isAuthenticated: false });
      });
  };

  /**
   * Manages logout
   */
  logout = () => {
    fetch("/auth/logout", {
      credentials: "same-origin",
    })
      .then(this.isResponseOk)
      .then((data) => {
        this.setState({ isAuthenticated: false });
        this.getCSRF();
        this.loginReset();
      })
      .catch((err) => {});
  };

  /**
   * Update email field on state
   * @param {event} e
   */
  onChangeMail = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   * Update password field on state
   * @param {event} e
   */
  onChangePass = (e) => {
    let pass = e.target.value;

    const hashedPassword = bcrypt.hashSync(
      pass,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    ); // hash created previously created upon sign up
    // const hashedPassword = pass;
    this.setState({ password: hashedPassword });
  };

  /**
   * Verify if page is open on a PC or smartphone/Tablet
   * @returns check
   */
  finc() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  /**
   * Rendering
   * @returns Component
   */
  render() {
    return (
      <div className="log">
        {this.finc() === true ? (
          <Alert
            style={{ height: 100 }}
            message="A aplicação só funciona em Desktop."
            type="warning"
            showIcon
          />
        ) : (
          ""
        )}

        <div className="login">
          <Card className="login_card">
            <Form
              layout="vertical"
              id="login"
              fields={[
                {
                  name: ["email"],
                  value: this.state.email,
                },
              ]}
              labelCol={{ span: 10 }}
              onFinish={this.login}
            >
              <h4>Login</h4>
              <p></p>
              <Form.Item
                label="Email:"
                name="email"
                rules={[
                  {
                    required: "true",
                    pattern: new RegExp(
                      "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@tectank.pt$"
                    ),
                    message: "Email inválido",
                  },
                ]}
              >
                <Input onChange={this.onChangeMail} name="email" required />
                <p />
              </Form.Item>

              <Form.Item label="Password:" name="password" required>
                <Input.Password onChange={this.onChangePass} required />
              </Form.Item>

              <Form.Item>
                <Button className="botao" htmlType="submit">
                  Entrar
                </Button>
              </Form.Item>
            </Form>

            {this.state.isAuthenticated === true ? (
              <Navigate to={this.state.login} />
            ) : (
              ""
            )}
          </Card>
        </div>
        <Footer className="footer">
          <div className="social">
            <Space>
              Powered by{" "}
              <a style={{ color: "#F94D5A" }} href="https://tectank.pt/">
                Tectank
              </a>
            </Space>
          </div>
        </Footer>
      </div>
    );
  }
}

/**
 * Maps variabels to redux store*/
function mapStateToProps(state) {
  return {
    name: state.name,
    email: state.email,
    password: state.password,
  };
}

/**
 * Maps functions to redux store*/
function mapDispatchToProps(dispatch) {
  return {
    loginReset: () => dispatch(loginReset()),
    userReset: () => dispatch(userReset()),
    updateData: (data) => dispatch(updateData(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
//_______________________________
